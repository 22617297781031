import qs, { type ParsedQs } from 'qs';

class QueryStringHelper {
  static parse = (str: string): ParsedQs => {
    const query = qs.parse(str, {
      ignoreQueryPrefix: true,
    });

    return query;
  };

  static stringify = (query: ParsedQs): string => {
    const { page, sort, size, ...rest } = query;

    return qs.stringify({
      ...rest,
      page: page,
      sort: sort,
      size: size,
    }, {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
      encode: false,
      encodeValuesOnly: true,
    });
  };
}

export default QueryStringHelper;
