import BaseAppBootstrap, { config, type StoreModuleByPreloadedState } from '../../base/client/BaseAppBootstrap';
import type { IModule } from '../../base/common/store/IStoreModule';
import mapModule from '../common/store/modules/map/mapModule';

abstract class BaseGisAppBootstrap extends BaseAppBootstrap {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected override createInitialModules(): IModule<any>[] {
    const initialModules = [
      ...super.createInitialModules(),
      mapModule,
    ];

    return initialModules;
  }

  protected override createModulesByPreloadedStateConfig(): StoreModuleByPreloadedState[] {
    const result: StoreModuleByPreloadedState[] = [
      ...super.createModulesByPreloadedStateConfig(),
      config(async () => {
        const geocodeModule = (await import('../common/store/modules/geocode/geocodeModule')).default;
        return geocodeModule;
      }, (state) => state.geocode),
      config(async () => {
        const neighborhoodFormModule = (await import('../common/store/modules/neighborhoodForm/neighborhoodFormModule')).default;
        return neighborhoodFormModule;
      }, (state) => state.neighborhoodForm),
    ];

    return result;
  }
}

export default BaseGisAppBootstrap;
