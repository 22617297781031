abstract class BaseApplicationError extends Error {

  public abstract readonly type: string;

  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here

    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export default BaseApplicationError;
