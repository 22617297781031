import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonAccentPrimary from '../../framework/base/common/components/shared/elements/buttons/ButtonAccentPrimary';
import { BASE_WEB_ROUTE_PATH_TERMS } from '../../framework/base/common/constants/path/baseWebRoutePaths';
import useUser from '../../framework/base/common/hooks/useUser';
import styles from './CookiesAlert.module.scss';

// let renderCounter = 0;

const CookiesAlert: React.FC = () => {

  // console.debug(`Render CookiesAlert ${++renderCounter}`);

  const user = useUser();

  const [showAlert, setShowAlter] = useState(false);

  const handleOnClick = useCallback(() => {
    localStorage.setItem('cookies-alter', true.toString());

    setShowAlter(false);
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('cookies-alter', true.toString());

      setShowAlter(false);
    }
  }, [user]);

  useEffect(() => {
    if (!localStorage.getItem('cookies-alter')) {
      setShowAlter(true);
    }
  }, []);

  return showAlert
    ? (
      <aside id={styles['app-cookies-alert']}>
        <div id={styles['app-div']}>
          <p id={styles['app-p']}>
            Korzystając z serwisu akceptujesz
            {' '}
            <Link to={BASE_WEB_ROUTE_PATH_TERMS}>regulamin</Link>
            {' '}
            i pliki cookie.
          </p>
          <ButtonAccentPrimary onClick={handleOnClick}>
            Ok
          </ButtonAccentPrimary>
        </div>
      </aside>
    )
    : null;
};

export default CookiesAlert;
