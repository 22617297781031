import ErrorTypeEnum from '../../constants/enums/ErrorTypeEnum';
import BaseApplicationError from './base/BaseApplicationError';

class ApplicationError extends BaseApplicationError {
  public readonly type = ErrorTypeEnum.ApplicationError;

  public unknownError?: unknown;
  public innerErrorMessage?: unknown;
  public innerErrorStack?: unknown;
  public innerErrorName?: unknown;
  public isAxiosError?: boolean | undefined;
  public isCancel?: boolean | undefined;
}

export default ApplicationError;
