import React from 'react';
import { Helmet } from 'react-helmet';
import useGoogleAnalytics from '../../../framework/base/common/hooks/useGoogleAnalytics';

const Meta: React.FC = () => {

  useGoogleAnalytics();

  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>WieszGdzieZwierz</title>
    </Helmet>
  );
};

export default Meta;
