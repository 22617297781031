import React from 'react';
import { FLOATING_MAP_CONTAINER_ID } from '../../../../../client/repositories/FloatingMapRepositoryConstants';

const FloatingMapPlaceholder: React.FC = () => {
  return (
    <aside id='floating-map-placeholder' className='d-none'>
      <div id={FLOATING_MAP_CONTAINER_ID} className='w-100 h-100' />
    </aside>
  );
};

export default FloatingMapPlaceholder;
