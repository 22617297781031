import { config, type StoreModuleByPreloadedState } from '../framework/base/client/BaseAppBootstrap';
import BaseGisAppBootstrap from '../framework/gis/client/BaseGisAppBootstrap';

class AppBootstrap extends BaseGisAppBootstrap {

  protected override createModulesByPreloadedStateConfig(): StoreModuleByPreloadedState[] {
    const result: StoreModuleByPreloadedState[] = [
      ...super.createModulesByPreloadedStateConfig(),
      config(async () => {
        const homeAdvertsModule = (await import('../common/store/modules/home/adverts/homeAdvertsModule')).default;
        return homeAdvertsModule;
      }, (state) => state.homeAdverts),
      config(async () => {
        const homeSheltersModule = (await import('../common/store/modules/home/shelters/homeSheltersModule')).default;
        return homeSheltersModule;
      }, (state) => state.homeShelters),
      config(async () => {
        const advertModule = (await import('../common/store/modules/advert/advertModule')).default;
        return advertModule;
      }, (state) => state.advert),
      config(async () => {
        const shelterModule = (await import('../common/store/modules/shelter/shelterModule')).default;
        return shelterModule;
      }, (state) => state.shelter),
      config(async () => {
        const vetModule = (await import('../common/store/modules/vet/vetModule')).default;
        return vetModule;
      }, (state) => state.vet),
      config(async () => {
        const locationDetailModule = (await import('../common/store/modules/locationDetail/locationDetailModule')).default;
        return locationDetailModule;
      }, (state) => state.locationDetail),
      config(async () => {
        const locationsListModule = (await import('../common/store/modules/locationsList/locationsListModule')).default;
        return locationsListModule;
      }, (state) => state.locationsList),
      config(async () => {
        const microchipModule = (await import('../common/store/modules/microchip/microchipModule')).default;
        return microchipModule;
      }, (state) => state.microchip),
      config(async () => {
        const auxiliaryModule = (await import('../common/store/modules/auxiliary/auxiliaryModule')).default;
        return auxiliaryModule;
      }, (state) => state.auxiliary),
      config(async () => {
        const profileModule = (await import('../common/modules/profile/store/modules/profile/profileModule')).default;
        return profileModule;
      }, (state) => state.profile),
    ];

    return result;
  }
}

export default AppBootstrap;
