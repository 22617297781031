import type { IModule } from '../../IStoreModule';
import commonReducer from './commonReducer';
import type { IAppAwareState } from './models/IAppAwareState';
import notificationsReducer from './notificationsReducer';
import userReducer from './userReducer';

const appModule: IModule<IAppAwareState> = {
  id: 'app',
  reducersMap: {
    common: commonReducer,
    notifications: notificationsReducer,
    user: userReducer,
  },
};

export default appModule;
