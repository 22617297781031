import { combineReducers, type Action, type Reducer, type ReducersMapObject } from 'redux';

interface IReducersManager {
  reducer: Reducer;
  add: (toAddReducersMap: ReducersMapObject) => void;
  remove: (toRemoveReducersMap: ReducersMapObject) => void;
}

function getReducerManager<TState>(initialReducersMap: ReducersMapObject<TState, Action>): IReducersManager {

  let reducerKeysToRemove: (keyof TState)[] = [];

  let currentReducersMap: ReducersMapObject<TState, Action> = {
    ...initialReducersMap,
  };

  let combinedReducer: Reducer<TState, Action> = combineReducers(currentReducersMap) as unknown as Reducer<TState, Action>;

  const rootReducer: Reducer<TState, Action> = (state: TState | undefined, action: Action): TState => {
    if (reducerKeysToRemove.length > 0) {
      state = { ...state } as TState;

      for (const key of reducerKeysToRemove) {
        delete state[key];
      }

      reducerKeysToRemove = [];
    }

    const producedState = combinedReducer(state, action);

    return producedState;
  };

  function add(toAddReducersMap: ReducersMapObject): void {
    currentReducersMap = {
      ...currentReducersMap,
      ...toAddReducersMap,
    };

    combinedReducer = combineReducers(currentReducersMap) as unknown as Reducer<TState, Action>;
  }

  function remove(toRemoveReducersMap: ReducersMapObject): void {
    for (const key in toRemoveReducersMap) {
      reducerKeysToRemove.push(key as keyof TState);

      delete currentReducersMap[key as keyof TState];
    }

    combinedReducer = combineReducers(currentReducersMap) as unknown as Reducer<TState, Action>;
  }

  const result: IReducersManager = {
    reducer: rootReducer,
    add,
    remove,
  };

  return result;
}

export type {
  IReducersManager
};

export {
  getReducerManager
};

