import type { BaseEntitiesApiQuery } from '../../../models/queries/base/BaseEntitiesApiQuery';
import type { BaseEntitiesApiQueryFilters } from '../../../models/queries/base/BaseEntitiesApiQueryFilters';
import type { BaseEntitiesQuery } from '../../../models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../models/queries/base/BaseEntitiesQueryFilters';
import BaseEntitiesQueryMapper from './BaseEntitiesQueryMapper';

abstract class BaseEntitiesQueryMapperNoAbstractQuery<
  TQueryFilters extends BaseEntitiesQueryFilters,
  TApiQueryFilters extends BaseEntitiesApiQueryFilters,
> extends BaseEntitiesQueryMapper<
  TQueryFilters,
  BaseEntitiesQuery<TQueryFilters>,
  TApiQueryFilters,
  BaseEntitiesApiQuery<TApiQueryFilters>
> {
  public override mapQueryToApiQuery(query: BaseEntitiesQuery<TQueryFilters>): BaseEntitiesApiQuery<TApiQueryFilters> {
    const apiQuery: BaseEntitiesApiQuery<TApiQueryFilters> = super.mapQueryToApiQueryIntrinsic(query);

    return apiQuery;
  }
}

export default BaseEntitiesQueryMapperNoAbstractQuery;
