import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import HomePageUrlQueryMapper from '../../../../common/helpers/mappers/HomePageUrlQueryMapper';
import type { HomePageQuery } from '../../../../common/models/system/home/queries/HomePageQuery';
import type { IMapAwareState } from '../store/modules/map/models/IMapAwareState';

const mapper = HomePageUrlQueryMapper.factory();

// let renderCounter = 0;

const useMapUrlSearchFromStore = (): string | undefined => {

  // console.debug(`Render useMapUrlSearchFromStore ${++renderCounter}`);

  const mapParamsQuery = useSelector((state: Partial<IMapAwareState>) => state.map?.params);
  const mapLocation = useSelector((state: Partial<IMapAwareState>) => state.mapLocation?.data);

  const result = useMemo(() => {
    if (mapParamsQuery) {
      const homePageQuery: HomePageQuery = {
        map: mapParamsQuery,
        location: mapLocation,
      };

      const result = mapper.stringifyForURL(homePageQuery);

      return result;
    } else {
      return undefined;
    }
  }, [mapLocation, mapParamsQuery]);

  return result;
};

export default useMapUrlSearchFromStore;
