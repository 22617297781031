import type { AnyAction } from 'redux';
import type { BaseEntitiesQuery } from '../../../../../base/common/models/queries/base/BaseEntitiesQuery';
import fetchByQueryOrIdAsyncActionCreatorsFactory from '../../../../../base/common/store/modules/base/fetchByQueryOrId/fetchByQueryOrIdAsyncActionCreatorsFactory';
import fetchByQueryOrIdReducerFactory from '../../../../../base/common/store/modules/base/fetchByQueryOrId/fetchByQueryOrIdReducerFactory';
import LocationsService from '../../../../client/services/LocationsService';
import type { LocationDetailWithGeoApiModel } from '../../../models/api/location/LocationDetailWithGeoApiModel';
import type { LocationsQueryFilters } from '../../../models/entities/location/queries/LocationsQueryFilters';
import type { MapLocationState } from './models/MapLocationState';

const namespace = 'map-location';

const initialState: MapLocationState = {};

const mapLocationActionCreators = fetchByQueryOrIdAsyncActionCreatorsFactory<
  LocationDetailWithGeoApiModel,
  BaseEntitiesQuery<LocationsQueryFilters>
>(
  namespace,
  async (query) => await (new LocationsService().getItemWithGeoByQuery(query)),
  async (id) => await (new LocationsService().getItemWithGeo(id))
);

function mapLocationReducer(state: MapLocationState | undefined, action: AnyAction): MapLocationState {
  if (action.type.startsWith(`${namespace}/`)) {
    return fetchByQueryOrIdReducerFactory(namespace, initialState)(state, action);
  }

  return state ?? initialState;
}

export {
  mapLocationActionCreators
};

export default mapLocationReducer;
