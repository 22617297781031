import type { AxiosRequestConfig } from 'axios';
import BasePublicGetService from './BasePublicGetService';

abstract class BaseGetService extends BasePublicGetService {

  constructor(routePath: string, protected jwt?: string) {
    super(routePath);
  }

  protected override getConfig(config?: AxiosRequestConfig): AxiosRequestConfig | undefined {
    const base = super.getConfig(config);

    if (this.jwt) {
      return {
        ...base,
        headers: {
          Authorization: 'bearer ' + this.jwt,
        },
      };
    }

    return base;
  }
}

export default BaseGetService;
