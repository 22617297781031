enum ErrorTypeEnum {
  NotFound404 = 'NOT_FOUND_404',
  ApplicationError = 'APPLICATION',
  FormError = 'FORM',
  ErrorMessageError = 'ERROR_MESSAGE',
  InfoMessageError = 'INFO_MESSAGE',
  OAuthError = 'OAUTH_ERROR',
}

export default ErrorTypeEnum;
