import { BASE_API_ROUTE_PATH_AUTH_PART_RETRIEVE } from '../../common/constants/path/baseApiRoutePathsAuth';
import type { SignInResult } from '../../common/models/auth/SignInResult';
import BaseGetService from './base/BaseGetService';

class AuthRetrieveService extends BaseGetService {

  constructor(jwt: string) {
    super('/auth', jwt);
  }

  public retrieve = async (): Promise<SignInResult> => {
    return await this.sendGet<SignInResult>(BASE_API_ROUTE_PATH_AUTH_PART_RETRIEVE);
  };
}

export default AuthRetrieveService;
