import BaseEntitiesQueryMapperNoAbstractQuery from '../../../../base/common/helpers/mappers/base/BaseEntitiesQueryMapperNoAbstractQuery';
import type { LocationsApiQueryFilters } from '../../models/entities/location/queries/LocationsApiQueryFilters';
import type { LocationsQueryFilters } from '../../models/entities/location/queries/LocationsQueryFilters';

class LocationsQueryMapper extends BaseEntitiesQueryMapperNoAbstractQuery<
  LocationsQueryFilters,
  LocationsApiQueryFilters
> {
  public override mapQueryFiltersToApiQueryFilters(queryFilters: LocationsQueryFilters): LocationsApiQueryFilters {
    const apiQueryFilters: LocationsApiQueryFilters = super.mapQueryFiltersToApiQueryFiltersIntrinsic(queryFilters);

    apiQueryFilters.localNameSlugs = queryFilters.localNameSlugs;

    return apiQueryFilters;
  }
}

export default LocationsQueryMapper;
