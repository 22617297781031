import { actionTypeFetchById } from '../../../actions/actionTypes';
import type { FSAP } from '../../../actions/base/fsa';
import type { FetchActionCreators } from '../fetch/dataActionCreators';
import fetchActionCreatorsFactory from '../fetch/fetchActionCreatorsFactory';

interface FetchByIdActionCreators<
  TData,
> extends FetchActionCreators<
  TData
> {
  fetchById: (id: string) => FSAP<string>;
}

function fetchByIdActionCreator(namespace: string, id: string): FSAP<string> {
  return {
    type: `${namespace}/${actionTypeFetchById}`,
    payload: id,
  };
}

function fetchByIdActionCreatorsFactory<TData>(
  namespace: string,
): FetchByIdActionCreators<TData> {
  const result: FetchByIdActionCreators<TData> = {
    ...fetchActionCreatorsFactory<TData>(namespace),
    fetchById: (query) => fetchByIdActionCreator(namespace, query),
  };

  return result;
}

export type {
  FetchByIdActionCreators
};

export {
  fetchByIdActionCreator
};

export default fetchByIdActionCreatorsFactory;
