import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UserRoleEnum from '../constants/UserRoleEnum';
import useUser from './useUser';

const googleAnalyticsMeasurementId = process.env['CLIENT_BUILD_GOOGLE_ANALYTICS_MEASUREMENT_ID'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const gtag: any;

const useGoogleAnalytics = (): void => {

  const { pathname, search } = useLocation();

  const user = useUser();

  const pagePath = `${pathname}${search}`;

  useEffect(() => {
    if (googleAnalyticsMeasurementId) {
      if (typeof gtag === 'function') {
        gtag('config', googleAnalyticsMeasurementId, { 'send_page_view': false });
      }
    }
  }, []);

  useEffect(() => {
    if (googleAnalyticsMeasurementId && (!user || !user.roles?.includes(UserRoleEnum.Admin))) {
      if (typeof gtag === 'function') {
        gtag('set', 'page_path', pagePath);
        gtag('event', 'page_view');
      }
    }
  }, [pagePath, user]);
};

export default useGoogleAnalytics;
