import formatDateHomePage from '../../helpers/date/formatDateHomePage';
import parseIso from '../../helpers/date/parseIso';

// Entities are ordered by date, cache latest format
let previousUtcString: string;
let previousResult: string | undefined;

function appFormatDateHomePageClient(utcIsoString: string): string | undefined {
  if (utcIsoString === previousUtcString) {
    return previousResult;
  }

  const date = parseIso(utcIsoString);

  previousUtcString = utcIsoString;
  previousResult = date
    ? formatDateHomePage(date)
    : undefined;

  return previousResult;
}

export default appFormatDateHomePageClient;
