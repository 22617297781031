import type { ThunkAction } from 'redux-thunk';
import type BaseApplicationError from '../../../../models/errors/base/BaseApplicationError';
import { actionTypeClear, actionTypeFailed, actionTypeSucceeded } from '../../../actions/actionTypes';
import type { FSA, FSAP } from '../../../actions/base/fsa';
import type { BaseFailedPayload } from '../../../actions/models/payloads/base/BaseFailedPayload';
import type { BaseItemsRequestPayload } from '../../../actions/models/payloads/base/BaseItemsRequestPayload';
import type { BaseSucceededPayload } from '../../../actions/models/payloads/base/BaseSucceededPayload';
import type { BaseQueryDataState, BaseQueryDataStateActionData } from '../../../actions/models/state/common/BaseQueryDataState';

type CreateThunkActionById<
  TData,
> = ThunkAction<
  void,
  BaseQueryDataStateActionData<TData>,
  void,
  FSAP<BaseFailedPayload> | FSAP<string> | FSAP<BaseSucceededPayload<TData>>
>;

type CreateThunkAction<
  TData,
  TQuery,
> = ThunkAction<
  void,
  BaseQueryDataState<TData, TQuery>,
  void,
  FSAP<BaseFailedPayload> | FSAP<BaseItemsRequestPayload<TQuery>> | FSAP<BaseSucceededPayload<TData>>
>;

interface FetchActionCreators<
  TData,
> {
  clear: () => FSA;
  failed: (error: BaseApplicationError) => FSAP<BaseFailedPayload>;
  succeed: (data: TData) => FSAP<BaseSucceededPayload<TData>>;
}

function clearActionCreator(namespace: string): FSA {
  return {
    type: `${namespace}/${actionTypeClear}`,
  };
}

function failedActionCreator(namespace: string, error: BaseApplicationError): FSAP<BaseFailedPayload> {
  return {
    type: `${namespace}/${actionTypeFailed}`,
    payload: {
      error,
    },
  };
}

function succeedActionCreator<TData>(namespace: string, data: TData): FSAP<BaseSucceededPayload<TData>> {
  return {
    type: `${namespace}/${actionTypeSucceeded}`,
    payload: {
      data,
    },
  };
}

// const fetchAsyncActionCreatorFactory = <
//   TData,
//   TQueryOrId,
// >(
//   queryOrId: TQueryOrId,
//   fetchActionCreators: FetchActionCreators<TData>,
//   qwe: (queryOrId: TQueryOrId) => Action,
//   asd: (queryOrId: TQueryOrId) => Promise<TData>,
// ): ThunkAction<void, unknown, unknown, Action> => async (dispatch) => {
//   dispatch(qwe(queryOrId));

//   try {
//     const data = await asd(queryOrId);

//     dispatch(fetchActionCreators.succeed(data));
//   } catch (error) {
//     if (error instanceof BaseApplicationError) {
//       dispatch(fetchActionCreators.failed(error));
//     } else {
//       dispatch(fetchActionCreators.failed(new ApplicationError()));
//     }
//   }
// };

export type {
  CreateThunkAction,
  CreateThunkActionById,
  FetchActionCreators
};

export {
  clearActionCreator,
  failedActionCreator,
  succeedActionCreator
};

