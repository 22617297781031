const months = [
  'stycznia',
  'lutego',
  'marca',
  'kwietnia',
  'maja',
  'czerwca',
  'lipca',
  'sierpnia',
  'września',
  'października',
  'listopada',
  'grudnia',
];

function formatDateHomePage(date: Date): string | undefined {
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
}

export default formatDateHomePage;
