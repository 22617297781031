import type { AnyAction, Reducer } from 'redux';
import type { FSA } from '../../../../../base/common/store/actions/base/fsa';
import type { MapWalkThroughState } from './models/MapWalkThroughState';

const namespace = 'map-walk-through';

const initialState: MapWalkThroughState = {
  searchMapCompleted: undefined,
  setPositionOnMapCompleted: undefined,
};

const mapWalkThroughSearchCompleted = (): FSA => ({
  type: `${namespace}/search-completed`,
});

const mapWalkThroughSearchUncompleted = (): FSA => ({
  type: `${namespace}/search-uncompleted`,
});

const mapWalkThroughSetPositionCompleted = (): FSA => ({
  type: `${namespace}/set-position-completed`,
});

const mapWalkThroughSetPositionUncompleted = (): FSA => ({
  type: `${namespace}/set-position-uncompleted`,
});

const mapWalkThroughReducer: Reducer<MapWalkThroughState, AnyAction> = (state: MapWalkThroughState | undefined, action: AnyAction): MapWalkThroughState => {
  if (action.type.startsWith(`${namespace}/`)) {
    switch (action.type) {
      case `${namespace}/search-completed`:
        return {
          ...state,
          searchMapCompleted: true,
        };
      case `${namespace}/search-uncompleted`:
        return {
          ...state,
          searchMapCompleted: undefined,
        };
      case `${namespace}/set-position-completed`:
        return {
          ...state,
          setPositionOnMapCompleted: true,
        };
      case `${namespace}/set-position-uncompleted`:
        return {
          ...state,
          setPositionOnMapCompleted: undefined,
        };
    }
  }

  return state ?? initialState;
};

export {
  mapWalkThroughSearchCompleted,
  mapWalkThroughSearchUncompleted,
  mapWalkThroughSetPositionCompleted,
  mapWalkThroughSetPositionUncompleted
};

export default mapWalkThroughReducer;
