import type { BaseEntitiesQuery } from '../../../../models/queries/base/BaseEntitiesQuery';
import type { FetchActionCreators } from '../fetch/dataActionCreators';
import fetchActionCreatorsFactory from '../fetch/fetchActionCreatorsFactory';
import { fetchByIdActionCreator } from '../fetchById/fetchByIdActionCreatorsFactory';
import { fetchByIdAsyncActionCreatorFactory, type FetchByIdAsyncActionCreators } from '../fetchById/fetchByIdAsyncActionCreatorsFactory';
import { fetchByQueryActionCreator } from '../fetchByQuery/fetchByQueryActionCreatorsFactory';
import { fetchByQueryAsyncActionCreatorFactory, type FetchByQueryAsyncActionCreators } from '../fetchByQuery/fetchByQueryAsyncActionCreatorsFactory';

type FetchByQueryOrIdAsyncActionCreators<
  TData,
  TQuery extends BaseEntitiesQuery,
> = FetchByQueryAsyncActionCreators<
  TData,
  TQuery
> & FetchByIdAsyncActionCreators<
  TData
>;

function fetchByQueryOrIdAsyncActionCreatorsFactory<
  TData,
  TQuery extends BaseEntitiesQuery
>(
  namespace: string,
  fetchQuery: (query: TQuery) => Promise<TData>,
  fetchId: (id: string) => Promise<TData>,
): FetchByQueryOrIdAsyncActionCreators<TData, TQuery> {
  const fetchActionCreators: FetchActionCreators<TData> = fetchActionCreatorsFactory<TData>(namespace);

  const fetchByQuery = (query: TQuery) => fetchByQueryActionCreator(namespace, query);
  const fetchById = (id: string) => fetchByIdActionCreator(namespace, id);

  const result: FetchByQueryOrIdAsyncActionCreators<TData, TQuery> = {
    ...fetchActionCreators,
    fetchByQuery,
    fetchByQueryAsync: (query: TQuery) => fetchByQueryAsyncActionCreatorFactory(query, { ...fetchActionCreators, fetchByQuery }, fetchQuery),
    fetchById,
    fetchByIdAsync: (id: string) => fetchByIdAsyncActionCreatorFactory(id, { ...fetchActionCreators, fetchById }, fetchId),
  };

  return result;
}

export type {
  FetchByQueryOrIdAsyncActionCreators
};

export default fetchByQueryOrIdAsyncActionCreatorsFactory;
