import loadable from '@loadable/component';
import React from 'react';
import { useSelector } from 'react-redux';
import type { IMapAwareState } from '../../../framework/gis/common/store/modules/map/models/IMapAwareState';

const FooterContent = loadable(() => import(/* webpackChunkName: "app-footer-content" */'./FooterContent'));

const Footer: React.FC = () => {

  const showMap = useSelector((state: IMapAwareState) => state.map?.showMap);

  return !showMap
    ? (
      <FooterContent />
    )
    : null;
};

export default Footer;
