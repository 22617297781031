import type { IModule } from '../../../../../base/common/store/IStoreModule';
import mapLocationReducer from './mapLocationReducer';
import mapReducer from './mapReducer';
import mapWalkThroughReducer from './mapWalkThroughReducer';
import type { IMapAwareState } from './models/IMapAwareState';

const mapModule: IModule<IMapAwareState> = {
  id: 'map',
  reducersMap: {
    map: mapReducer,
    mapLocation: mapLocationReducer,
    mapWalkThrough: mapWalkThroughReducer,
  },
};

export default mapModule;
