import { composeWithDevTools } from '@redux-devtools/extension';
import type { Action, ReducersMapObject, Store, StoreEnhancer } from 'redux';
import { applyMiddleware, compose, legacy_createStore } from 'redux';
import type { ThunkMiddleware } from 'redux-thunk';
import type { IModule } from '../IStoreModule';
import type { IAppAwareState } from '../modules/app/models/IAppAwareState';
import { getReducerManager, type IReducersManager } from './reducersManager';

type IModuleStore<State> = Store<State> & {
  addModule: (module: IModule<unknown>) => void;
  addModules: (modules: IModule<unknown>[]) => void;
  isModuleAdded: (module: IModule<unknown>) => boolean;
  removeModule: (module: IModule<unknown>) => void;
  removeModules: (modules: IModule<unknown>[]) => void;
};

// function createStoreReduxToolkit<TState extends IAppAwareState>(
//   reducersManager: IReducersManager,
//   preloadedState: TState,
//   middlewares: ThunkMiddleware<TState>[],
// ): Store<TState> {
//   const options: ConfigureStoreOptions<TState> = {
//     reducer: reducersManager.reducer,
//     preloadedState: preloadedState,
//     middleware: () => new Tuple(...middlewares),
//     devTools: process.env['NODE_ENV'] === 'development',
//   };

//   const store = configureStore(options) as IModuleStore<TState>;

//   return store;
// }

function createStoreLegacyRedux<TState extends IAppAwareState>(
  reducersManager: IReducersManager,
  preloadedState: TState,
  middlewares: ThunkMiddleware<TState>[],
): Store<TState> {
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  const composedEnhancers: StoreEnhancer<TState> = process.env['NODE_ENV'] === 'development'
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : compose(...enhancers);

  const store = legacy_createStore<TState, Action>(
    reducersManager.reducer,
    preloadedState,
    composedEnhancers
  );

  return store;
}

function getReducersMapFromModules(modules: IModule<unknown>[]): ReducersMapObject {
  const result = modules.reduce<ReducersMapObject>((previousValue, currentValue) => {
    return {
      ...previousValue,
      ...currentValue.reducersMap,
    };
  }, {});

  return result;
}

function appStoreFactory<TState extends IAppAwareState>(
  middlewares: ThunkMiddleware<TState>[],
  preloadedState: TState,
  initialModules: IModule<unknown>[],
) {
  const moduleIds = initialModules.map((module) => module.id);

  const reducersManager = getReducerManager(getReducersMapFromModules(initialModules));

  const store = createStoreLegacyRedux(
    reducersManager,
    preloadedState,
    middlewares,
  ) as IModuleStore<TState>;

  function isModuleAdded(module: IModule<unknown>): boolean {
    return moduleIds.includes(module.id);
  }

  function addModule(module: IModule<unknown>) {
    addModules([module]);
  }

  function addModules(modules: IModule<unknown>[]) {
    const modulesToAdd = modules.filter((module) => !isModuleAdded(module));

    if (modulesToAdd.length) {
      moduleIds.push(...modulesToAdd.map((module) => module.id));

      reducersManager.add(getReducersMapFromModules(modulesToAdd));

      store.dispatch({
        type: '@@APP/module/added',
        payload: modulesToAdd.map((module) => module.id),
      });
    }
  }

  function removeModule(module: IModule<unknown>): void {
    removeModules([module]);
  }

  function removeModules(modules: IModule<unknown>[]): void {
    const modulesToRemove = modules.filter(isModuleAdded);

    if (modulesToRemove.length) {
      let reducersMapToRemove = {};

      for (const moduleToRemove of modulesToRemove) {
        const index = moduleIds.indexOf(moduleToRemove.id);
        moduleIds.splice(index, 1);

        reducersMapToRemove = {
          ...reducersMapToRemove,
          ...moduleToRemove.reducersMap,
        };
      }

      reducersManager.add(reducersMapToRemove);

      store.dispatch({
        type: '@@APP/module/removed',
        payload: modulesToRemove.map((module) => module.id),
      });
    }
  }

  store.addModule = addModule;
  store.addModules = addModules;
  store.removeModule = removeModule;
  store.removeModules = removeModules;
  store.isModuleAdded = isModuleAdded;

  return store;
}

export type {
  IModuleStore
};

export {
  appStoreFactory
};

