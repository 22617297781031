export const BASE_WEB_ROUTE_PATH_ABOUT = '/about';
export const BASE_WEB_ROUTE_PATH_CONFIRM_EMAIL = '/confirm-email';
export const BASE_WEB_ROUTE_PATH_CONTACT = '/contact';
export const BASE_WEB_ROUTE_PATH_FORGOTTEN_PASSWORD = '/forgotten-password';
export const BASE_WEB_ROUTE_PATH_PRIVACY = '/privacy';
export const BASE_WEB_ROUTE_PATH_RESET_PASSWORD = '/reset-password';
export const BASE_WEB_ROUTE_PATH_RODO = '/rodo';
export const BASE_WEB_ROUTE_PATH_SIGNIN = '/signin';
export const BASE_WEB_ROUTE_PATH_SIGNUP = '/signup';
export const BASE_WEB_ROUTE_PATH_SITEMAP = '/sitemap';
export const BASE_WEB_ROUTE_PATH_TERMS = '/terms';
