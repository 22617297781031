enum EntityTypeEnum {
  Advert = 'advert',
  Pet = 'pet',
  Petsitter = 'petsitter',
  Shelter = 'shelter',
  Vet = 'vet',
  WeeklyReport = 'weekly-report',
}

export default EntityTypeEnum;
