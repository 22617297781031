import type { BaseEntityDetailApiModel } from '../../../common/models/api/base/BaseEntityDetailApiModel';
import BaseGetService from './BaseGetService';

abstract class BaseEntitiesServiceReadonly<
  TDetailApiModel extends BaseEntityDetailApiModel,
> extends BaseGetService {

  constructor(routePath: string, jwt?: string) {
    super(routePath, jwt);
  }

  public async getItem(id: string): Promise<TDetailApiModel> {
    return this.sendGet<TDetailApiModel>(`/${id}`);
  }
}

export default BaseEntitiesServiceReadonly;
