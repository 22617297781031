import loadable from '@loadable/component';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { ICommonAwareState } from '../../../../framework/base/common/store/modules/app/models/ICommonAwareState';
import type BaseGisEntityTypeEnum from '../../../../framework/gis/common/constants/enums/BaseGisEntityTypeEnum';
import { SHORT_ID_LENGTH_ADVERT, SHORT_ID_LENGTH_SHELTER } from '../../../constants/ShortIdConstants';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import { HOME_PAGE_FRIENDLY_URLS_REPOSITORY, type FriendlyUrlRouteConfig, type FriendlyUrlRoutePredefinedQuery } from './AppRouterFriendlyUrlRepository';

const AppRouter = loadable(() => import(
  /* webpackChunkName: "app-router" */
  './AppRouter'
));

const HomePage = loadable(() => import(
  /* webpackChunkName: "page-home" */
  '../../pages/home/HomePage'
));

const MainMapOptimized = loadable(() => import(
  /* webpackChunkName: "main-map" */
  '../../shared/elements/map/main/MainMapOptimized'
));

const advertEntitySlugRegexp = new RegExp(`^\\/[\\w-]+-\\d{1,2}-\\w+-\\d{4}-[\\w-]+-\\w{${SHORT_ID_LENGTH_ADVERT}}$`);
const shelterEntitySlugRegexp = new RegExp(`^\\/[\\w-]+-\\w{${SHORT_ID_LENGTH_SHELTER}}$`);

function findSegment(pathname: string): [string, string | undefined] {
  const index = pathname.indexOf('/', 1);

  if (index === -1) {
    return [pathname, undefined];
  } else {
    const segment = pathname.substring(0, index);
    const others = pathname.substring(index);

    return [segment, others];
  }
}

interface HomePageRouteMatch {
  routePredefinedQuery?: FriendlyUrlRoutePredefinedQuery | undefined;
  entityType?: EntityTypeEnum | undefined;
  entityId?: string | undefined;
  entitySlug?: string | undefined;
  formEntityType?: EntityTypeEnum | BaseGisEntityTypeEnum | undefined;
}

function createRouteMatchDictionary(
  routeConfigRepository: Record<string, FriendlyUrlRouteConfig>,
  pathname: string,
  parentPredefinedQuery: FriendlyUrlRoutePredefinedQuery | undefined
): HomePageRouteMatch | undefined {
  const [segment, otherSegments] = findSegment(pathname);

  const routeConfig = routeConfigRepository[segment];

  if (!routeConfig) {
    return undefined;
  }

  const predefinedQuery = {
    ...parentPredefinedQuery,
    ...routeConfig.predefinedQuery,
  };

  if (!otherSegments) {
    return routeConfig.formEntityType
      ? { formEntityType: routeConfig.formEntityType }
      : { routePredefinedQuery: predefinedQuery };
  }

  const childRouteMatch = routeConfig.children
    ? createRouteMatchDictionary(routeConfig.children, otherSegments, predefinedQuery)
    : undefined;

  if (childRouteMatch) {
    return childRouteMatch;
  }

  // Advert slug

  if (routeConfig.useAdvertSlug) {
    if (otherSegments.match(advertEntitySlugRegexp)) {
      return {
        entityType: EntityTypeEnum.Advert,
        entitySlug: otherSegments.slice(1),
      };
    }
  }

  // Shelter slug

  if (routeConfig.useShelterSlug) {
    const [nextSegment] = findSegment(otherSegments); // other might be /koty, /psy

    if (nextSegment.match(shelterEntitySlugRegexp)) {
      return {
        entityType: EntityTypeEnum.Shelter,
        entitySlug: nextSegment.slice(1),
      };
    }
  }

  // Location slugs

  if (routeConfig.useLocationSlug) {
    return {
      routePredefinedQuery: {
        ...predefinedQuery,
        locationSlugs: otherSegments.slice(1).split('/'),
      }
    };
  }

  if (routeConfig.enityType) {
    const [guidSegment] = findSegment(otherSegments);

    if (guidSegment) {
      return {
        entityType: routeConfig.enityType,
        entityId: guidSegment.slice(1),
      };
    } else {
      return undefined;
    }
  }

  if (routeConfig.formEntityType) {
    return {
      formEntityType: routeConfig.formEntityType
    };
  }

  return undefined;
}

function createRouteMatchFromPathname(pathname: string): HomePageRouteMatch | undefined {
  if (pathname === '/') {
    return {
      routePredefinedQuery: {},
    };
  }

  const pathnameNoTralingSlash = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
  const pathnameNoTralingSlashDecoded = decodeURIComponent(pathnameNoTralingSlash);

  const routeMatch = createRouteMatchDictionary(HOME_PAGE_FRIENDLY_URLS_REPOSITORY, pathnameNoTralingSlashDecoded, undefined);

  return routeMatch;
}

// let renderCounter = 0;

const AppRouterFriendlyUrl: React.FC = () => {

  const { pathname } = useLocation();

  const routeMatch = createRouteMatchFromPathname(pathname);

  const useHomePage = routeMatch && (routeMatch.routePredefinedQuery || (routeMatch.entityType));

  const showPageNotFound404 = useSelector((state: ICommonAwareState) => state.common.showPageNotFound404);

  // console.debug(`Render AppRouterFriendlyUrl ${++renderCounter}`, pathname, useHomePage);

  return (
    <>
      <div id='main-content'>
        {useHomePage && (
          <HomePage
            predefinedQuery={routeMatch.routePredefinedQuery}
            detailModeEntityType={routeMatch.entityType}
            detailModeEntityId={routeMatch.entityId}
            detailModeEntitySlug={routeMatch.entitySlug}
          />
        )}
        {!useHomePage && (
          <AppRouter />
        )}
      </div>
      <div id='map-content'>
        {routeMatch !== undefined && !showPageNotFound404 && (
          <MainMapOptimized
            detailModeEntityType={routeMatch?.entityType}
            formEntityType={routeMatch?.formEntityType}
            predefinedQuery={routeMatch?.routePredefinedQuery}
            useFriendlyUrls={!routeMatch?.formEntityType}
            isDetail={routeMatch?.entityType !== undefined}
          />
        )}
      </div>
      {/* <div id='map-content'>
        <MainMap
          showMap={routeMatch !== undefined}
          detailModeEntityType={routeMatch?.entityType}
          formEntityType={routeMatch?.formEntityType}
          predefinedQuery={routeMatch?.routePredefinedQuery}
          useFriendlyUrls={!routeMatch?.formEntityType}
          isDetail={routeMatch?.entityType !== undefined}
        />
      </div> */}
    </>
  );
};

export default AppRouterFriendlyUrl;
