import React, { type CSSProperties } from 'react';

interface SpinnerProps {
  className?: string;
  style?: CSSProperties;
}

const Spinner: React.FC<SpinnerProps> = ({ className, style }: SpinnerProps) => {
  return (
    <span
      className={`spinner-border${className ? ` ${className}` : ''}`}
      style={style}
      role='status'
    />
  );
};

export default Spinner;
