import { useSelector } from 'react-redux';
import type { IUserAwareState } from '../store/modules/app/models/IUserAwareState';

const useJwt = (): string | undefined => {
  const jwt = useSelector((state: IUserAwareState) => state.user.jwt);

  return jwt;
};

export default useJwt;
