import { actionTypeFetchByQuery } from '../../../actions/actionTypes';
import type { FSAP } from '../../../actions/base/fsa';
import type { BaseItemsRequestPayload } from '../../../actions/models/payloads/base/BaseItemsRequestPayload';
import type { FetchActionCreators } from '../fetch/dataActionCreators';
import fetchActionCreatorsFactory from '../fetch/fetchActionCreatorsFactory';

interface FetchByQueryActionCreators<TData, TQuery> extends FetchActionCreators<TData> {
  fetchByQuery: (query: TQuery) => FSAP<BaseItemsRequestPayload<TQuery>>;
}

function fetchByQueryActionCreator<TQuery>(namespace: string, query: TQuery): FSAP<BaseItemsRequestPayload<TQuery>> {
  return {
    type: `${namespace}/${actionTypeFetchByQuery}`,
    payload: {
      query,
    },
  };
}

function fetchByQueryActionCreatorsFactory<TData, TQuery>(namespace: string): FetchByQueryActionCreators<TData, TQuery> {
  const result: FetchByQueryActionCreators<TData, TQuery> = {
    ...fetchActionCreatorsFactory<TData>(namespace),
    fetchByQuery: (query) => fetchByQueryActionCreator(namespace, query),
  };

  return result;
}

export type {
  FetchByQueryActionCreators
};

export {
  fetchByQueryActionCreator
};

export default fetchByQueryActionCreatorsFactory;
