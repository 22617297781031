import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import ApplicationError from '../../../common/models/errors/ApplicationError';
import type BaseApplicationError from '../../../common/models/errors/base/BaseApplicationError';
import NotFound404Error from '../../models/errors/NotFound404Error';

abstract class BasePublicGetService {

  constructor(protected routePath: string) { }

  protected async sendGet<T>(routePath: string, config?: AxiosRequestConfig): Promise<T> {
    try {
      const url = this.url(routePath);
      const response = await axios.get<T>(url, this.getConfig(config));
      return response.data;
    } catch (err) {
      throw this.parseError(err);
    }
  }

  protected getConfig(config?: AxiosRequestConfig): AxiosRequestConfig | undefined {
    return config;
  }

  protected url(...routePaths: string[]): string {
    const result = ['/api', this.routePath, ...routePaths].filter((p) => p).join('');
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected parseError(error: any): BaseApplicationError {
    let status: number | undefined;
    let statusText: string | undefined;
    let data: unknown;

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const axiosError: AxiosError = error as AxiosError;
      const axiosResponse = axiosError.response;
      if (axiosResponse) {
        status = axiosResponse.status;
        statusText = axiosResponse.statusText;
        data = axiosResponse.data;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      const xmlHttpRequest = error.request as XMLHttpRequest;
      status = xmlHttpRequest.status;
      statusText = xmlHttpRequest.statusText;
      data = xmlHttpRequest.response;
    } else {
      // Something happened in setting up the request that triggered an Error
      status = undefined;
      statusText = error.message;
      data = undefined;
    }

    if (status === 404) {
      return new NotFound404Error();
    }

    return this.parseApplicationError(status, statusText, data, error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  protected parseApplicationError(_status: number | undefined, _statusText: string | undefined, _data: any, error: any): BaseApplicationError {
    const applicationError = new ApplicationError();

    applicationError.isAxiosError = axios.isAxiosError(error);
    applicationError.isCancel = axios.isCancel(error);

    return applicationError;
  }
}

export default BasePublicGetService;
