import ApplicationError from '../../../../models/errors/ApplicationError';
import BaseApplicationError from '../../../../models/errors/base/BaseApplicationError';
import type { CreateThunkAction } from '../fetch/dataActionCreators';
import fetchByQueryActionCreatorsFactory, { type FetchByQueryActionCreators } from './fetchByQueryActionCreatorsFactory';

interface FetchByQueryAsyncActionCreators<TData, TQuery> extends FetchByQueryActionCreators<TData, TQuery> {
  fetchByQueryAsync: (query: TQuery, jwt?: string | undefined) => CreateThunkAction<TData, TQuery>;
}

function fetchByQueryAsyncActionCreatorFactory<TData, TQuery>(
  query: TQuery,
  fetchByQueryActionCreators: FetchByQueryActionCreators<TData, TQuery>,
  fetch: (query: TQuery, jwt?: string | undefined) => Promise<TData>,
  jwt?: string | undefined,
): CreateThunkAction<TData, TQuery> {
  return async (dispatch) => {
    dispatch(fetchByQueryActionCreators.fetchByQuery(query));

    try {
      const data = await fetch(query, jwt);

      dispatch(fetchByQueryActionCreators.succeed(data));
    } catch (error) {
      if (error instanceof BaseApplicationError) {
        dispatch(fetchByQueryActionCreators.failed(error));
      } else {
        dispatch(fetchByQueryActionCreators.failed(new ApplicationError()));
      }
    }
  };
}

function fetchByQueryAsyncActionCreatorsFactory<TData, TQuery>(
  namespace: string,
  fetch: (query: TQuery, jwt?: string | undefined) => Promise<TData>,
): FetchByQueryAsyncActionCreators<TData, TQuery> {
  const fetchByQueryActionCreators = fetchByQueryActionCreatorsFactory<TData, TQuery>(namespace);

  const result: FetchByQueryAsyncActionCreators<TData, TQuery> = {
    ...fetchByQueryActionCreators,
    fetchByQueryAsync: (query: TQuery, jwt?: string | undefined) => fetchByQueryAsyncActionCreatorFactory(query, fetchByQueryActionCreators, fetch, jwt),
  };

  return result;
}

export type {
  FetchByQueryAsyncActionCreators
};

export {
  fetchByQueryAsyncActionCreatorFactory
};

export default fetchByQueryAsyncActionCreatorsFactory;
