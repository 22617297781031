import type { Path, Search } from 'react-router-dom';
import { BASE_RENDERER_ROUTE_PATHS_HOME } from '../../../framework/base/common/constants/path/baseRendererRoutePaths';
import type { DeepReadonly } from '../../../framework/base/common/constants/types/UtilityTypes';
import QueryStringHelper from '../../../framework/base/common/helpers/QueryStringHelper';
import parseUrlArrayTaxonomyFilterValue from '../../../framework/base/common/helpers/mappers/url/parseUrlArrayTaxonomyFilterValue';
import BaseLatLngUsersEntitiesUrlQueryMapper from '../../../framework/gis/common/helpers/mappers/base/BaseLatLngUsersEntitiesUrlQueryMapper';
import type { BaseLatLngUsersEntitiesQueryLocation } from '../../../framework/gis/common/models/queries/base/BaseLatLngUsersEntitiesQuery';
import AppRouterFriendlyUrlRepository, { HOME_SEO_CREATED_AT_RECENTLY, type FriendlyUrlRoutePredefinedQuery } from '../../components/layout/routers/AppRouterFriendlyUrlRepository';
import { TAXONOMY_ANIMALS, TAXONOMY_TYPES, TAXONOMY_TYPE_FOUND, TAXONOMY_TYPE_LOST } from '../../constants/TaxonomyConstants';
import AdvertPublicCreatedAtPeriodEnum from '../../constants/enums/AdvertPublicCreatedAtPeriodEnum';
import EntityTypeEnum from '../../constants/enums/EntityTypeEnum';
import type { HomePageQuery } from '../../models/system/home/queries/HomePageQuery';
import type { HomePageQueryFilters } from '../../models/system/home/queries/HomePageQueryFilters';
import type { HomePageUrlQuery } from '../../models/system/home/queries/HomePageUrlQuery';

const LOCATION_SLUGS_LIMIT = 3;

const HOME_PAGE_ADVERT_FILTERS_PUBLIC_CREATED_PERDIO_DEFAULT = AdvertPublicCreatedAtPeriodEnum.Recent2Weeks;

class HomePageUrlQueryMapper extends BaseLatLngUsersEntitiesUrlQueryMapper<
  HomePageUrlQuery,
  HomePageQueryFilters,
  HomePageQuery
> {

  private static instanceHomePageFriendlyUrlQueryMapper?: HomePageUrlQueryMapper;

  public static factory = (): HomePageUrlQueryMapper => {
    if (!HomePageUrlQueryMapper.instanceHomePageFriendlyUrlQueryMapper) {
      HomePageUrlQueryMapper.instanceHomePageFriendlyUrlQueryMapper = new HomePageUrlQueryMapper();
    }

    return HomePageUrlQueryMapper.instanceHomePageFriendlyUrlQueryMapper;
  };

  public override parse(search: Search, predefinedQuery?: FriendlyUrlRoutePredefinedQuery, detailModeEntityType?: EntityTypeEnum | undefined): DeepReadonly<HomePageQuery> {
    const result = this.parseHomePageFriendlyUrl(search, detailModeEntityType);

    if (predefinedQuery) {
      const slugs: string[] | undefined = predefinedQuery.locationSlugs ? predefinedQuery.locationSlugs : result.location?.slugs;

      let location: BaseLatLngUsersEntitiesQueryLocation | undefined = result.location;

      if (slugs) {
        if (!location) {
          location = {
            slugs,
          };
        } else {
          location.slugs = slugs;
        }
      }

      const animals = predefinedQuery.animal ? [predefinedQuery.animal] : result.filters?.animal;
      const types = predefinedQuery.type ? [predefinedQuery.type] : result.filters?.type;
      const advertPublicCreatedAtPeriod = predefinedQuery.advertPublicCreatedAtPeriod ?? result.filters?.advertPublicCreatedAtPeriod;

      const filtersAnimal = animals ? { animal: animals } : undefined;
      const filtersType = types ? { type: types } : undefined;
      const filtersAdvertPublicCreatedAtPeriod = advertPublicCreatedAtPeriod ? { advertPublicCreatedAtPeriod } : undefined;

      return {
        ...result,
        filters: {
          ...result.filters,
          ...filtersAnimal,
          ...filtersType,
          ...filtersAdvertPublicCreatedAtPeriod,
        },
        tab: predefinedQuery.tab ?? result.tab,
        location: location,
      };
    } else {
      return result;
    }
  }

  public override mapUrlQueryToQuery(urlQuery: HomePageUrlQuery): HomePageQuery {
    const query: HomePageQuery = super.mapUrlQueryToQueryIntrinsic(urlQuery);

    const tab = this.mapUrlQueryTabToQueryTab(urlQuery.tab);

    if (tab) {
      query.tab = tab;
    }

    if (urlQuery.active) {
      query.active = urlQuery.active;
    }

    if (urlQuery.activePin) {
      query.activePin = urlQuery.activePin;
    }

    if (urlQuery.expand) {
      query.expand = true;
    }

    return query;
  }

  public override mapQueryToUrlQuery(query: HomePageQuery): HomePageUrlQuery {
    const urlQuery: HomePageUrlQuery = super.mapQueryToUrlQueryIntrinsic(query);

    const queryFilters = query.filters;

    if (queryFilters) {
      if (queryFilters.animal) {
        urlQuery.animal = queryFilters.animal;
      }

      if (queryFilters.type) {
        urlQuery.type = queryFilters.type;
      }

      if (queryFilters.advertPublicCreatedAtPeriod && queryFilters.advertPublicCreatedAtPeriod !== HOME_PAGE_ADVERT_FILTERS_PUBLIC_CREATED_PERDIO_DEFAULT) { // TODO
        urlQuery.date = queryFilters.advertPublicCreatedAtPeriod;
      }

      if (queryFilters.gender) {
        urlQuery.gender = queryFilters.gender;

        if (queryFilters.genderIncludeNotSet) {
          urlQuery.genderIncludeNotSet = queryFilters.genderIncludeNotSet;
        }
      }

      if (queryFilters.sizes) {
        urlQuery.sizes = queryFilters.sizes;
      }
    }

    switch (query.tab) {
      case EntityTypeEnum.Advert:
        urlQuery.tab = undefined;
        break;
      case EntityTypeEnum.Vet:
        urlQuery.tab = EntityTypeEnum.Vet;
        break;
      case EntityTypeEnum.Shelter:
        urlQuery.tab = EntityTypeEnum.Shelter;
        break;
      default:
        urlQuery.tab = undefined;
    }

    urlQuery.active = query.active;
    urlQuery.activePin = query.activePin;
    urlQuery.expand = query.expand ? '1' : undefined;

    return urlQuery;
  }

  public generateHomePageFriendlyUrl(query: DeepReadonly<HomePageQuery>, pathname?: string | undefined): Path {
    if (!pathname) {
      const filters: DeepReadonly<HomePageQueryFilters> | undefined = query?.filters;

      if ((query.tab === EntityTypeEnum.Advert || query.tab === undefined) && filters) {
        const onlyAnimal = filters.animal?.length === 1 ? filters.animal[0] : undefined;
        const onlyType = filters.type?.length === 1 ? filters.type[0] : undefined;

        if (onlyAnimal || onlyType) {
          let pathname = '';

          const queryCopy = {
            ...query,
            filters: {
              ...query.filters,
            }
          };

          if (onlyAnimal) {
            const onlyAnimalPath = AppRouterFriendlyUrlRepository.pathByAnimalTaxonomy[onlyAnimal];

            if (onlyAnimalPath) {
              pathname += onlyAnimalPath;
              queryCopy.filters.animal = undefined;
            }
          }

          if (onlyType) {
            const onlyTypePath = AppRouterFriendlyUrlRepository.pathByTypeTaxonomy[onlyType];

            if (onlyTypePath) {
              pathname += onlyTypePath;
              queryCopy.filters.type = undefined;

              if ([
                TAXONOMY_TYPE_FOUND,
                TAXONOMY_TYPE_LOST
              ].includes(onlyType) && filters.advertPublicCreatedAtPeriod === AdvertPublicCreatedAtPeriodEnum.RecentWeek) {
                pathname += HOME_SEO_CREATED_AT_RECENTLY;
                queryCopy.filters.advertPublicCreatedAtPeriod = undefined;
              }
            }
          }

          if (onlyAnimal && onlyType && query.location) {
            queryCopy.location = undefined;
            queryCopy.map = undefined;

            const slugsForPathname = query.location?.slugs?.slice(0, LOCATION_SLUGS_LIMIT);
            const slugsRest = query.location?.slugs?.slice(LOCATION_SLUGS_LIMIT);
            const pathnameSlugs = slugsForPathname && slugsForPathname.length > 0 ? slugsForPathname.join('/') : undefined;

            pathname += pathnameSlugs ? '/' + pathnameSlugs : '';

            // There is more slugs than can be fit in pathname, append location ID in search query
            if (slugsRest && slugsRest.length > 0) {
              if (query.location.id) {
                queryCopy.location = {
                  id: query.location.id
                };
              } else {
                console.warn('Something is wrong, more than allowed limit slugs were used but no ID was provided', query.location);
              }
            }
          }

          return this.stringifyQueryForUrl(pathname, queryCopy);
        }
      }

      if (query.tab === EntityTypeEnum.Shelter || query.tab === EntityTypeEnum.Petsitter) {
        const byTab = AppRouterFriendlyUrlRepository.pathByTab[query.tab];

        if (byTab) {
          const producedQuery: DeepReadonly<HomePageQuery> = {
            ...query,
            tab: EntityTypeEnum.Advert,
          };

          return this.stringifyQueryForUrl(byTab, producedQuery);
        }
      }
    }

    return this.stringifyQueryForUrl(pathname ?? BASE_RENDERER_ROUTE_PATHS_HOME, query);
  }

  protected override createEmptyQuery(): HomePageQuery {
    const result: HomePageQuery = {};

    return result;
  }

  protected override mapUrlQueryToQueryFilters(urlQuery: HomePageUrlQuery): HomePageQueryFilters {
    const queryFilters: HomePageQueryFilters = super.mapUrlQueryToQueryFiltersIntrinsic(urlQuery);

    const type = urlQuery.type ? parseUrlArrayTaxonomyFilterValue(urlQuery.type, TAXONOMY_TYPES) : undefined;
    const animals = urlQuery.animal ? parseUrlArrayTaxonomyFilterValue(urlQuery.animal, TAXONOMY_ANIMALS) : undefined;
    const advertPublicCreatedAtPeriod = urlQuery.date;
    const gender = urlQuery.gender;
    const genderIncludeNotSet = urlQuery.genderIncludeNotSet;
    const sizes = urlQuery.sizes
      ? Array.isArray(urlQuery.sizes)
        ? urlQuery.sizes
        : [urlQuery.sizes]
      : undefined;

    if (type !== undefined || animals !== undefined || advertPublicCreatedAtPeriod !== undefined || gender !== undefined || sizes !== undefined) {

      if (animals != undefined) {
        queryFilters.animal = animals;
      }

      if (type !== undefined) {
        queryFilters.type = type;
      }

      if (advertPublicCreatedAtPeriod) {
        queryFilters.advertPublicCreatedAtPeriod = advertPublicCreatedAtPeriod;
      }

      if (gender !== undefined) {
        queryFilters.gender = gender;

        if (genderIncludeNotSet !== undefined) {
          queryFilters.genderIncludeNotSet = genderIncludeNotSet;
        }
      }

      if (sizes !== undefined) {
        queryFilters.sizes = sizes;
      }
    }

    return queryFilters;
  }

  private parseHomePageFriendlyUrl(search: Search, detailModeEntityType?: EntityTypeEnum | undefined): Readonly<HomePageQuery> {
    if (search === '') {
      const result = this.createEmptyQuery();

      result.tab = result.tab ?? detailModeEntityType;

      return result;
    } else {
      const noContext = QueryStringHelper.parse(search);

      if (!noContext['tab']) {
        if (detailModeEntityType) {
          noContext['tab'] = detailModeEntityType;
        }
      }

      const z = this.mapUrlQueryToQuery(noContext); // casting as unknown... but tab required - TODO
      const x: Readonly<HomePageQuery> = {
        ...z,
        tab: z.tab ?? EntityTypeEnum.Advert
      };

      return x;
    }
  }

  private mapUrlQueryTabToQueryTab(urlQueryTab: string | undefined): EntityTypeEnum | undefined {
    switch (urlQueryTab) {
      case EntityTypeEnum.Vet:
        return EntityTypeEnum.Vet;
      case EntityTypeEnum.Shelter:
        return EntityTypeEnum.Shelter;
      default:
        return undefined;
    }
  }

  private stringifyQueryForUrl(pathname: string, query: DeepReadonly<HomePageQuery>): Path {
    const search = this.stringifyForURL(query);

    return {
      pathname: pathname,
      search: search,
      hash: '',
    };
  }
}

export {
  HOME_PAGE_ADVERT_FILTERS_PUBLIC_CREATED_PERDIO_DEFAULT,
  LOCATION_SLUGS_LIMIT
};

export default HomePageUrlQueryMapper;
