import ApplicationError from '../../../../models/errors/ApplicationError';
import BaseApplicationError from '../../../../models/errors/base/BaseApplicationError';
import type { CreateThunkActionById } from '../fetch/dataActionCreators';
import type { FetchByIdActionCreators } from './fetchByIdActionCreatorsFactory';
import fetchByIdActionCreatorsFactory from './fetchByIdActionCreatorsFactory';

interface FetchByIdAsyncActionCreators<TData> extends FetchByIdActionCreators<TData> {
  fetchByIdAsync: (id: string) => CreateThunkActionById<TData>;
}

function fetchByIdAsyncActionCreatorFactory<TData>(
  id: string,
  fetchByIdActionCreators: FetchByIdActionCreators<TData>,
  fetch: (id: string) => Promise<TData>,
): CreateThunkActionById<TData> {
  return async (dispatch) => {
    dispatch(fetchByIdActionCreators.fetchById(id));

    try {
      const data = await fetch(id);

      dispatch(fetchByIdActionCreators.succeed(data));
    } catch (error) {
      if (error instanceof BaseApplicationError) {
        dispatch(fetchByIdActionCreators.failed(error));
      } else {
        dispatch(fetchByIdActionCreators.failed(new ApplicationError()));
      }
    }
  };
}

function fetchByIdAsyncActionCreatorsFactory<TData>(
  namespace: string,
  fetch: (id: string) => Promise<TData>,
): FetchByIdAsyncActionCreators<TData> {
  const fetchByIdActionCreators: FetchByIdActionCreators<TData> = fetchByIdActionCreatorsFactory<TData>(namespace);

  const result: FetchByIdAsyncActionCreators<TData> = {
    ...fetchByIdActionCreators,
    fetchByIdAsync: (id: string) => fetchByIdAsyncActionCreatorFactory(id, fetchByIdActionCreators, fetch),
  };

  return result;
}

export type {
  FetchByIdAsyncActionCreators
};

export {
  fetchByIdAsyncActionCreatorFactory
};

export default fetchByIdAsyncActionCreatorsFactory;
