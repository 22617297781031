import BaseAppCommonConstants from '../../framework/base/common/constants/BaseAppCommonConstants';

const DEFAULT_PAGINATION_SIZE = 10;

// THIS IS ONLY FOR PAGINATION - REMOVE IT

class AppCommonConstants extends BaseAppCommonConstants {

  public static override getInstance(): AppCommonConstants {
    return BaseAppCommonConstants.getInstance();
  }

  public static setup(): void {
    this.instance = new AppCommonConstants(DEFAULT_PAGINATION_SIZE);
  }
}

export default AppCommonConstants;
