import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import AuthRetrieveService from '../../../../client/services/AuthRetrieveService';
import type { AuthenticatedUserApiModel } from '../../../models/api/user/AuthenticatedUserApiModel';
import type { SignInResult } from '../../../models/auth/SignInResult';
import type { FSA, FSAP } from '../../actions/base/fsa';
import type { UserState } from './models/UserState';

const namespace = 'user';

const typeAuthenticate = 'authenticate';
const typeRetrieve = 'retrieve';
const typeRetrieveSucceeded = 'retrieveSucceeded';
const typeRetrieveFailed = 'retrieveFailed';
const typeSignout = 'signout';
const typeSetUser = 'setUser';
const typeShowWelcomeMessage = 'showWelcomeMessage';

interface RetrievePayload {
  jwt: string;
}

interface RetrieveSucceededPayload {
  user: AuthenticatedUserApiModel;
  jwt: string;
}

const initialState: UserState = {};

function userAuthenticate(data: SignInResult): FSAP<SignInResult> {
  return {
    type: `${namespace}/${typeAuthenticate}`,
    payload: data,
  };
}

function userRetrieveAsync(payload: RetrievePayload): ThunkAction<void, RetrievePayload, unknown, Action> {
  return async (dispatch) => {
    dispatch(userRetrieve());

    try {
      const data = await (new AuthRetrieveService(payload.jwt)).retrieve();

      dispatch(userRetrieveSucceeded(data));
    } catch (error) {
      dispatch(userRetrieveFailed());
    }
  };
}

function userRetrieve(): FSA {
  return {
    type: `${namespace}/${typeRetrieve}`,
  };
}

function userRetrieveSucceeded(data: RetrieveSucceededPayload): FSAP<RetrieveSucceededPayload> {
  return {
    type: `${namespace}/${typeRetrieveSucceeded}`,
    payload: data,
  };
}

function userRetrieveFailed(): FSA {
  return {
    type: `${namespace}/${typeRetrieveFailed}`
  };
}

function userSignout(): FSA {
  return {
    type: `${namespace}/${typeSignout}`
  };
}

function userShowWelcomeMessage(data: boolean): FSAP<boolean> {
  return {
    type: `${namespace}/${typeShowWelcomeMessage}`,
    payload: data,
  };
}

function userSetUser(data: RetrieveSucceededPayload): FSAP<RetrieveSucceededPayload> {
  return {
    type: `${namespace}/${typeSetUser}`,
    payload: data
  };
}

export type {
  RetrievePayload
};

function userReducer(state: UserState | undefined, action: Action): UserState {
  if (action.type.startsWith(`${namespace}/`)) {
    switch (action.type) {
      case `${namespace}/${typeAuthenticate}`:
        return {
          ...state,
          user: (action as FSAP<SignInResult>).payload.user,
          jwt: (action as FSAP<SignInResult>).payload.jwt,
        };
      case `${namespace}/${typeRetrieve}`:
        return {
          ...state,
          isFetching: true,
        };
      case `${namespace}/${typeRetrieveSucceeded}`:
        return {
          ...state,
          isFetching: undefined,
          user: (action as FSAP<RetrieveSucceededPayload>).payload.user,
          jwt: (action as FSAP<RetrieveSucceededPayload>).payload.jwt,
        };
      case `${namespace}/${typeRetrieveFailed}`:
        return {
          ...state,
          isFetching: undefined,
          user: undefined,
          jwt: undefined,
        };
      case `${namespace}/${typeSignout}`:
        return {
          ...state,
          user: undefined,
          jwt: undefined,
        };
      case `${namespace}/${typeSetUser}`:
        return {
          ...state,
          user: (action as FSAP<RetrieveSucceededPayload>).payload.user,
        };
      case `${namespace}/${typeShowWelcomeMessage}`:
        return {
          ...state,
          showWelcomeMessage: (action as FSAP<boolean>).payload ? true : undefined,
        };
    }
  }

  return state ?? initialState;
}

export {
  userAuthenticate,
  userRetrieveAsync,
  userRetrieveFailed,
  userRetrieveSucceeded,
  userSetUser,
  userShowWelcomeMessage,
  userSignout
};

export default userReducer;
