import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { BASE_RENDERER_ROUTE_PATHS_HOME } from '../../../framework/base/common/constants/path/baseRendererRoutePaths';
import { WEB_ROUTE_PATH_PAGE_LOVE } from '../../constants/path/webRoutePaths';
import styles from './Header.module.scss';
import MainNavigation from './header/MainNavigation';

// let renderCounter = 0;

const Header: React.FC = () => {

  // console.debug(`Render Header ${++renderCounter}`);

  return (
    <header id={styles['app-header']}>
      <div id={styles['app-header-div']}>
        <Link
          id={styles['app-brand-link']}
          to={BASE_RENDERER_ROUTE_PATHS_HOME}
          title='Wiesz Gdzie Zwierz'
        />
        <NavLink
          id={styles['app-love-link']}
          to={WEB_ROUTE_PATH_PAGE_LOVE}
          title='Love'
          className={({ isActive }) => isActive ? 'active' : undefined}
        />
      </div>
      <MainNavigation />
    </header>
  );
};

export default Header;
