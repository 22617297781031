export const TAXONOMY_BLANK = 'blank';

export const TAXONOMY_TYPE_LOST = 'lost';
export const TAXONOMY_TYPE_FOUND = 'found';
export const TAXONOMY_TYPE_ADOPT = 'adopt';
export const TAXONOMY_TYPES = [TAXONOMY_TYPE_LOST, TAXONOMY_TYPE_FOUND, TAXONOMY_TYPE_ADOPT];

export const TAXONOMY_ANIMAL_DOG = 'dog';
export const TAXONOMY_ANIMAL_CAT = 'cat';
export const TAXONOMY_ANIMAL_OTHER = 'other';
export const TAXONOMY_ANIMALS = [TAXONOMY_ANIMAL_DOG, TAXONOMY_ANIMAL_CAT, TAXONOMY_ANIMAL_OTHER];
