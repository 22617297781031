import React from 'react';
import '../../client/styles/main.scss';
import FloatingMapPlaceholder from '../../framework/gis/common/components/shared/elements/map/FloatingMapPlaceholder';
import CookiesAlert from './CookiesAlert';
import Footer from './layout/Footer';
import Header from './layout/Header';
import Meta from './layout/Meta';
import AppRouterFriendlyUrl from './layout/routers/AppRouterFriendlyUrl';

// let renderCounter = 0;

const AppComponent: React.FC = () => {

  // console.debug(`Render AppComponent ${++renderCounter}`);

  return (
    <>
      <Meta />
      <div id='app-wrapper'>
        <main id='app-main'>
          <AppRouterFriendlyUrl />
        </main>
        <Header />
      </div>
      <Footer />
      <aside id='modal-root' />
      <FloatingMapPlaceholder />
      <CookiesAlert />
    </>
  );
};

export default AppComponent;
