import React, { createContext, useContext, useState, type PropsWithChildren } from 'react';

interface AppLocalizationContextState {
  formatDate: (isoString: string) => string | undefined;
}

const AppLocalizationContext = createContext<AppLocalizationContextState>({
  formatDate: () => undefined,
});

interface AppLocalizationContextProviderProps {
  formatDate: (isoString: string) => string | undefined;
}

// let renderCounter = 0;

const AppLocalizationContextProvider: React.FC<PropsWithChildren<AppLocalizationContextProviderProps>> = ({
  formatDate,
  children,
}: PropsWithChildren<AppLocalizationContextProviderProps>) => {

  // console.debug(`Render AppLocalizationContextProvider ${++renderCounter}`);

  const [state] = useState<AppLocalizationContextState>({
    formatDate
  });

  return (
    <AppLocalizationContext.Provider value={state}>
      {children}
    </AppLocalizationContext.Provider>
  );
};

function useAppLocalizationContext(): AppLocalizationContextState {
  return useContext(AppLocalizationContext);
}

export {
  AppLocalizationContextProvider,
  useAppLocalizationContext
};

