import type { Action } from 'redux';
import type ErrorCodeEnum from '../../../constants/enums/ErrorCodeEnum';
import { APPLICATION_ERROR_MESSAGE } from '../../../helpers/BaseContent';
import type { BaseErrorPayload } from '../../../models/errors/payloads/base/BaseErrorPayload';
import type { FSA, FSAP } from '../../actions/base/fsa';
import type { NotificationsState } from './models/NotificationsState';

const namespace = 'notifications';

const actionTypeClearKeepAfterRedirect = 'clearKeepAfterRedirect';
const actionTypeClearMessages = 'clearMessages';
const actionTypeSetApplicationErrorMessage = 'setApplicationErrorMessage';
const actionTypeSetErrorCode = 'setErrorCode';
const actionTypeSetErrorMessage = 'setErrorMessage';
const actionTypeSetInfoMessage = 'setInfoMessage';
const actionTypeSetMessage = 'setMessage';

const initialState: NotificationsState = {};

interface BaseMessagePayload {
  dontScroll?: boolean;
  keepAfterRedirect?: boolean;
}

interface SetErrorCodePayload extends BaseMessagePayload {
  errorCode: ErrorCodeEnum;
  errorPayload: BaseErrorPayload;
}

interface SetErrorMessagePayload extends BaseMessagePayload {
  errorMessage: string;
}

interface SetInfoMessagePayload extends BaseMessagePayload {
  infoMessage: string;
}

interface SetMessagePayload extends BaseMessagePayload {
  message: string;
}

function clearMessages(): FSA {
  return {
    type: `${namespace}/${actionTypeClearMessages}`,
  };
}

function clearKeepAfterRedirect(): FSA {
  return {
    type: `${namespace}/${actionTypeClearKeepAfterRedirect}`,
  };
}

function setApplicationErrorMessage(data?: BaseMessagePayload | undefined): FSAP<BaseMessagePayload | undefined> {
  return {
    type: `${namespace}/${actionTypeSetApplicationErrorMessage}`,
    payload: data,
  };
}

function setErrorCode(data: SetErrorCodePayload): FSAP<SetErrorCodePayload> {
  return {
    type: `${namespace}/${actionTypeSetErrorCode}`,
    payload: data,
  };
}

function setErrorMessage(data: SetErrorMessagePayload): FSAP<SetErrorMessagePayload> {
  return {
    type: `${namespace}/${actionTypeSetErrorMessage}`,
    payload: data,
  };
}

function setInfoMessage(data: SetInfoMessagePayload): FSAP<SetInfoMessagePayload> {
  return {
    type: `${namespace}/${actionTypeSetInfoMessage}`,
    payload: data,
  };
}

function setMessage(data: SetMessagePayload): FSAP<SetMessagePayload> {
  return {
    type: `${namespace}/${actionTypeSetMessage}`,
    payload: data,
  };
}

function notificationsReducer(state: NotificationsState | undefined, action: Action): NotificationsState {
  if (action.type.startsWith(`${namespace}/`)) {
    switch (action.type) {
      case `${namespace}/${actionTypeClearMessages}`:
        return initialState;
      case `${namespace}/${actionTypeClearKeepAfterRedirect}`:
        return {
          ...state,
          keepAfterRedirect: undefined,
        };
      case `${namespace}/${actionTypeSetApplicationErrorMessage}`:
        return {
          ...initialState,
          errorMessage: APPLICATION_ERROR_MESSAGE,
          counter: state?.counter ? ++state.counter : 1,
          dontScroll: (action as FSAP<BaseMessagePayload | undefined>).payload?.dontScroll,
          keepAfterRedirect: (action as FSAP<BaseMessagePayload | undefined>).payload?.keepAfterRedirect,
        };
      case `${namespace}/${actionTypeSetErrorCode}`:
        return {
          ...initialState,
          errorCode: (action as FSAP<SetErrorCodePayload>).payload.errorCode,
          errorPayload: (action as FSAP<SetErrorCodePayload>).payload.errorPayload,
          counter: state?.counter ? ++state.counter : 1,
          dontScroll: (action as FSAP<SetErrorCodePayload>).payload.dontScroll,
          keepAfterRedirect: (action as FSAP<SetErrorCodePayload>).payload.keepAfterRedirect,
        };
      case `${namespace}/${actionTypeSetErrorMessage}`:
        return {
          ...initialState,
          errorMessage: (action as FSAP<SetErrorMessagePayload>).payload.errorMessage,
          counter: state?.counter ? ++state.counter : 1,
          dontScroll: (action as FSAP<SetErrorMessagePayload>).payload.dontScroll,
          keepAfterRedirect: (action as FSAP<SetErrorMessagePayload>).payload.keepAfterRedirect,
        };
      case `${namespace}/${actionTypeSetInfoMessage}`:
        return {
          ...initialState,
          infoMessage: (action as FSAP<SetInfoMessagePayload>).payload.infoMessage,
          counter: state?.counter ? ++state.counter : 1,
          dontScroll: (action as FSAP<SetInfoMessagePayload>).payload.dontScroll,
          keepAfterRedirect: (action as FSAP<SetInfoMessagePayload>).payload.keepAfterRedirect,
        };
      case `${namespace}/${actionTypeSetMessage}`:
        return {
          ...initialState,
          message: (action as FSAP<SetMessagePayload>).payload.message,
          counter: state?.counter ? state.counter + 1 : 1,
          dontScroll: (action as FSAP<SetMessagePayload>).payload.dontScroll,
          keepAfterRedirect: (action as FSAP<SetMessagePayload>).payload.keepAfterRedirect,
        };
    }
  }

  return state || initialState;
}

export type {
  BaseMessagePayload,
  SetErrorCodePayload,
  SetErrorMessagePayload,
  SetInfoMessagePayload,
  SetMessagePayload
};

export {
  clearKeepAfterRedirect,
  clearMessages,
  setApplicationErrorMessage,
  setErrorCode,
  setErrorMessage,
  setInfoMessage,
  setMessage
};

export default notificationsReducer;
