import type { Action, Reducer } from 'redux';
import type { BaseEntityDetailApiModel } from '../../../../models/api/base/BaseEntityDetailApiModel';
import type { BaseEntitiesQuery } from '../../../../models/queries/base/BaseEntitiesQuery';
import { actionTypeClear, actionTypeFailed, actionTypeFetchById, actionTypeFetchByQuery, actionTypeSucceeded } from '../../../actions/actionTypes';
import type { FSAP } from '../../../actions/base/fsa';
import type { BaseFailedPayload } from '../../../actions/models/payloads/base/BaseFailedPayload';
import type { BaseItemQueryPayload } from '../../../actions/models/payloads/base/BaseItemQueryPayload';
import type { BaseSucceededPayload } from '../../../actions/models/payloads/base/BaseSucceededPayload';
import type { EntityDetailState } from '../../../actions/models/state/entities/EntityDetailState';
import { failedCaseReducer, succeedCaseReducer } from '../fetch/fetchCaseReducers';

function reduceFetchByQuery<
  TState extends EntityDetailState<TEntityDetail, TQuery>,
  TEntityDetail extends BaseEntityDetailApiModel,
  TQuery extends BaseEntitiesQuery,
>(state: Readonly<TState>, action: Readonly<FSAP<BaseItemQueryPayload<TQuery>>>): TState {
  return {
    ...state,
    isFetching: true,
    query: action.payload.query,
    id: undefined,
  };
}

function reduceFetchById<
  TState extends EntityDetailState<TEntityDetail, TQuery>,
  TEntityDetail extends BaseEntityDetailApiModel,
  TQuery extends BaseEntitiesQuery,
>(state: Readonly<TState>, action: Readonly<FSAP<string>>): TState {
  return {
    ...state,
    isFetching: true,
    query: undefined,
    id: action.payload,
  };
}

function fetchByQueryOrIdReducerFactory<
  TState extends EntityDetailState<TEntityDetail, TQuery>,
  TEntityDetail extends BaseEntityDetailApiModel,
  TQuery extends BaseEntitiesQuery,
>(
  namespace: string,
  emptyState: TState,
): Reducer<
  TState,
  Action
> {

  const reducer: Reducer<
    TState,
    Action
  > = (
    state: TState | undefined,
    action: Action
  ): TState => {
      if (action.type.startsWith(`${namespace}/`)) {
        switch (action.type) {
          case `${namespace}/${actionTypeFetchByQuery}`:
            return reduceFetchByQuery<TState, TEntityDetail, TQuery>(state ?? emptyState, action as unknown as FSAP<BaseItemQueryPayload<TQuery>>);
          case `${namespace}/${actionTypeFetchById}`:
            return reduceFetchById<TState, TEntityDetail, TQuery>(state ?? emptyState, action as unknown as FSAP<string>);
          case `${namespace}/${actionTypeSucceeded}`:
            return succeedCaseReducer<TState, TEntityDetail>(state ?? emptyState, action as FSAP<BaseSucceededPayload<TEntityDetail>>);
          case `${namespace}/${actionTypeFailed}`:
            return failedCaseReducer<TState>(state ?? emptyState, action as unknown as FSAP<BaseFailedPayload>);
          case `${namespace}/${actionTypeClear}`:
            return emptyState;
        }
      }

      return state ?? emptyState;
    };

  return reducer;
}

export default fetchByQueryOrIdReducerFactory;
