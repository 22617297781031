import BaseEntitiesSearchServiceReadonly from '../../../base/client/services/base/BaseEntitiesSearchServiceReadonly';
import type { BaseEntitiesQuery } from '../../../base/common/models/queries/base/BaseEntitiesQuery';
import type { ItemsListModel } from '../../../base/common/models/system/pagination/ItemsListModel';
import LocationsQueryMapper from '../../common/helpers/mappers/LocationsQueryMapper';
import type { LocationDetailApiModel } from '../../common/models/api/location/LocationDetailApiModel';
import type { LocationDetailWithGeoApiModel } from '../../common/models/api/location/LocationDetailWithGeoApiModel';
import type { LocationListItemApiModel } from '../../common/models/api/location/LocationListItemApiModel';
import type { LocationsQueryFilters } from '../../common/models/entities/location/queries/LocationsQueryFilters';

class LocationsService extends BaseEntitiesSearchServiceReadonly<
  LocationDetailApiModel,
  LocationsQueryFilters,
  BaseEntitiesQuery<LocationsQueryFilters>,
  LocationsQueryMapper,
  LocationListItemApiModel
> {

  constructor(jwt?: string) {
    super('/locations', new LocationsQueryMapper(), jwt);
  }

  public async getItemWithGeo(id: string): Promise<LocationDetailWithGeoApiModel> {
    return this.sendGet<LocationDetailWithGeoApiModel>(`/${id}/detail-with-geo`);
  }

  public async getItemWithGeoByQuery(query: BaseEntitiesQuery<LocationsQueryFilters> | undefined): Promise<LocationDetailWithGeoApiModel> {
    return this.sendGetQuery<LocationDetailWithGeoApiModel>('/detail-with-geo', query ?? {});
  }

  public async getRootItems(): Promise<ItemsListModel<LocationListItemApiModel>> {
    return this.sendGet<ItemsListModel<LocationListItemApiModel>>('/root');
  }
}

export default LocationsService;
