export const BASE_API_ROUTE_PATH_AUTH = '/auth';
export const BASE_API_ROUTE_PATH_AUTH_PART_CONTACT = '/contact';
export const BASE_API_ROUTE_PATH_AUTH_PART_PROVIDERS = '/providers';
export const BASE_API_ROUTE_PATH_AUTH_PART_RESET_PASSWORD = '/reset-password';
export const BASE_API_ROUTE_PATH_AUTH_PART_RETRIEVE = '/retrieve';
export const BASE_API_ROUTE_PATH_AUTH_PART_SEND_RESET_PASSWORD_LINK = '/send-reset-password-link';
export const BASE_API_ROUTE_PATH_AUTH_PART_SIGNIN = '/signin';
export const BASE_API_ROUTE_PATH_AUTH_PART_SIGNIN_GOOGLE = '/signup-google';
export const BASE_API_ROUTE_PATH_AUTH_PART_SIGNOUT = '/signout';
export const BASE_API_ROUTE_PATH_AUTH_PART_SIGNUP = '/signup';
