import { clearActionCreator, failedActionCreator, succeedActionCreator, type FetchActionCreators } from './dataActionCreators';

function fetchActionCreatorsFactory<
  TData,
>(
  namespace: string,
): FetchActionCreators<TData> {
  const result: FetchActionCreators<TData> = {
    clear: () => clearActionCreator(namespace),
    failed: (error) => failedActionCreator(namespace, error),
    succeed: (data) => succeedActionCreator(namespace, data),
  };

  return result;
}

export default fetchActionCreatorsFactory;
