import type { LocalDateApiQuery } from '../../../models/queries/LocalDateApiQuery';
import type { BaseEntitiesApiQuery } from '../../../models/queries/base/BaseEntitiesApiQuery';
import type { BaseEntitiesApiQueryFilters } from '../../../models/queries/base/BaseEntitiesApiQueryFilters';
import type { BaseEntitiesQuery } from '../../../models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../models/queries/base/BaseEntitiesQueryFilters';
import type { PaginationApiQuery } from '../../../models/system/pagination/queries/PaginationApiQuery';
import QueryStringHelper from '../../QueryStringHelper';
import type { IEntitiesQueryMapper } from '../interfaces/IEntitiesQueryMapper';

abstract class BaseEntitiesQueryMapper<
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
  TQuery extends BaseEntitiesQuery<TQueryFilters> = BaseEntitiesQuery<TQueryFilters>,
  TApiQueryFilters extends BaseEntitiesApiQueryFilters = BaseEntitiesApiQueryFilters,
  TApiQuery extends BaseEntitiesApiQuery<TApiQueryFilters> = BaseEntitiesApiQuery<TApiQueryFilters>
> implements IEntitiesQueryMapper<TQuery> {

  public abstract mapQueryToApiQuery(query: TQuery): TApiQuery;

  public abstract mapQueryFiltersToApiQueryFilters(queryFilters: TQueryFilters): TApiQueryFilters;

  public static stringifyDateToApiQuery = (value?: Date): LocalDateApiQuery | undefined => {
    return value?.toISOString();
  };

  public stringifyForAPI(query: TQuery): string {
    const apiQuery = this.mapQueryToApiQuery(query);
    const result = QueryStringHelper.stringify(apiQuery);

    return result;
  }

  protected mapQueryToApiQueryIntrinsic(query: TQuery): BaseEntitiesApiQuery<TApiQueryFilters> {
    const apiQuery: BaseEntitiesApiQuery<TApiQueryFilters> = {};

    const filters = this.mapQueryToApiQueryFilters(query);
    const pagination = this.mapQueryToApiQueryPagination(query);

    if (filters) {
      apiQuery.filters = filters;
    }

    if (pagination) {
      apiQuery.pagination = pagination;
    }

    return apiQuery;
  }

  protected mapQueryFiltersToApiQueryFiltersIntrinsic(queryFilters: TQueryFilters): BaseEntitiesApiQueryFilters {
    const apiQueryFilters: BaseEntitiesApiQueryFilters = {};

    apiQueryFilters.createdFrom = BaseEntitiesQueryMapper.stringifyDateToApiQuery(queryFilters.createdFrom);
    apiQueryFilters.createdTo = BaseEntitiesQueryMapper.stringifyDateToApiQuery(queryFilters.createdTo);
    apiQueryFilters.id = queryFilters.id;
    apiQueryFilters.query = queryFilters.query;

    return apiQueryFilters;
  }

  private mapQueryToApiQueryFilters(query: TQuery): TApiQueryFilters | undefined {
    return query.filters
      ? this.mapQueryFiltersToApiQueryFilters(query.filters)
      : undefined;
  }

  private mapQueryToApiQueryPagination(query: TQuery): PaginationApiQuery | undefined {
    if (query.pagination && (query.pagination.page || query.pagination.size || query.pagination.sort)) {
      const pagination: PaginationApiQuery = {};

      if (query.pagination.page) {
        pagination.page = query.pagination.page.toString();
      }

      if (query.pagination.size) {
        pagination.size = query.pagination.size.toString();
      }

      if (query.pagination.sort) {
        pagination.sort = query.pagination.sort;
      }

      return pagination;
    } else {
      return undefined;
    }
  }
}

export default BaseEntitiesQueryMapper;
