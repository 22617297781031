import type { DeepReadonly } from '../../../../../base/common/constants/types/UtilityTypes';
import BaseEntitiesUrlQueryMapper from '../../../../../base/common/helpers/mappers/base/BaseEntitiesUrlQueryMapper';
import type { BaseLatLngUsersEntitiesQuery, BaseLatLngUsersEntitiesQueryLocation } from '../../../models/queries/base/BaseLatLngUsersEntitiesQuery';
import type { BaseLatLngUsersEntitiesQueryFilters } from '../../../models/queries/base/BaseLatLngUsersEntitiesQueryFilters';
import type { BaseLatLngUsersEntitiesUrlQuery } from '../../../models/queries/base/BaseLatLngUsersEntitiesUrlQuery';
import type { MapParamsUrlValueModel } from '../../../models/system/map/MapParamsUrlValueModel';
import type { MapParamsQuery } from '../../../models/system/map/queries/MapParamsQuery';

abstract class BaseLatLngUsersEntitiesUrlQueryMapper<
  TUrlQuery extends BaseLatLngUsersEntitiesUrlQuery = BaseLatLngUsersEntitiesUrlQuery,
  TQueryFilters extends BaseLatLngUsersEntitiesQueryFilters = BaseLatLngUsersEntitiesQueryFilters,
  TQuery extends BaseLatLngUsersEntitiesQuery<TQueryFilters> = BaseLatLngUsersEntitiesQuery<TQueryFilters>
> extends BaseEntitiesUrlQueryMapper<
  TUrlQuery,
  TQueryFilters,
  TQuery
> {

  private static stringifyMap(params: MapParamsUrlValueModel): string {
    const parts: string[] = [];

    for (const [key, value] of Object.entries(params)) {
      parts.push(`${key}-${value}`);
    }

    const result = parts.join('-');

    return result;
  }

  protected override mapUrlQueryToQueryIntrinsic(urlQuery: TUrlQuery): DeepReadonly<BaseLatLngUsersEntitiesQuery<TQueryFilters>> {
    const query: BaseLatLngUsersEntitiesQuery<TQueryFilters> = super.mapUrlQueryToQueryIntrinsic(urlQuery);

    const location: BaseLatLngUsersEntitiesQueryLocation | undefined = urlQuery.location
      ? {
        id: urlQuery.location,
      }
      : undefined;

    const map: MapParamsQuery | undefined = !urlQuery.location && urlQuery.map
      ? this.mapUrlQueryMapToQueryMap(urlQuery.map)
      : query.map;

    if (location) {
      query.location = location;
    }

    if (map) {
      query.map = map;
    }

    return query;
  }

  protected override mapUrlQueryToQueryFiltersIntrinsic(urlQuery: TUrlQuery): BaseLatLngUsersEntitiesQueryFilters {
    const queryFilters: BaseLatLngUsersEntitiesQueryFilters = super.mapUrlQueryToQueryFiltersIntrinsic(urlQuery);

    const title = urlQuery.title ? this.parseTextUrlQueryValue(urlQuery.title) : undefined;

    if (title) {
      queryFilters.title = title;
    }

    return queryFilters;
  }

  protected override mapQueryToUrlQueryIntrinsic(query: Readonly<TQuery>): BaseLatLngUsersEntitiesUrlQuery {
    const urlQuery: BaseLatLngUsersEntitiesUrlQuery = super.mapQueryToUrlQueryIntrinsic(query);

    urlQuery.location = query.location?.id;

    if (query.map && (!query.location || (!query.location.id && (!query.location.slugs || query.location.slugs.length === 0)))) {
      urlQuery.map = this.mapQueryMapToUrlQueryMap(query.map);
    }

    const queryFilters = query.filters;

    if (queryFilters) {
      if (queryFilters.title) {
        urlQuery.title = queryFilters.title;
      }
    }

    return urlQuery;
  }

  private mapUrlQueryMapToQueryMap(urlQueryMap: string): MapParamsQuery | undefined {
    const parts = urlQueryMap.match(/[\w.]+--?\d+(\.\d+)?/g);

    if (!parts || parts.length < 2) {
      return undefined;
    }

    const r: Record<string, number> = {};
    const values = parts.reduce((accumulator, currentValue) => {
      const parts = currentValue.split(/-(.+)/);

      if (parts[0] && parts[1]) {
        return {
          ...accumulator,
          [parts[0]]: parseFloat(parts[1]),
        };
      } else {
        return accumulator;
      }
    }, r);

    const lat = values['lat'];
    const lng = values['lng'];
    const zoom = values['z'];

    if (!lat || !lng || !zoom) {
      return undefined;
    }

    const map: MapParamsQuery = {
      center: {
        lat: lat,
        lng: lng,
      },
      zoom: zoom,
    };

    if (values['n'] && values['s'] && values['e'] && values['w']) {
      map.bounds = {
        ne: {
          lat: values['n'],
          lng: values['e'],
        },
        sw: {
          lat: values['s'],
          lng: values['w'],
        },
      };
    }

    return map;
  }

  private mapQueryMapToUrlQueryMap(queryMap: DeepReadonly<MapParamsQuery>): string {
    const map: MapParamsUrlValueModel = {};

    if (queryMap.center && queryMap.zoom) {
      map.lat = queryMap.center.lat;
      map.lng = queryMap.center.lng;
      map.z = queryMap.zoom;
    }

    if (queryMap.bounds) {
      map.n = queryMap.bounds.ne.lat;
      map.s = queryMap.bounds.sw.lat;
      map.e = queryMap.bounds.ne.lng;
      map.w = queryMap.bounds.sw.lng;
    }

    const result = BaseLatLngUsersEntitiesUrlQueryMapper.stringifyMap(map);

    return result;
  }
}

export default BaseLatLngUsersEntitiesUrlQueryMapper;
