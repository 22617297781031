import type { Action } from 'redux';
import type { FSAP } from '../../actions/base/fsa';
import type { CommonState } from './models/CommonState';

const namespace = 'common';

const actionType404 = '404';

const initialState: CommonState = {};

function setShowPageNotFound404(data: boolean): FSAP<boolean> {
  return {
    type: `${namespace}/${actionType404}`,
    payload: data,
  };
}

function commonReducer(state: CommonState | undefined, action: Action): CommonState {
  if (action.type.startsWith(`${namespace}/`)) {
    switch (action.type) {
      case `${namespace}/${actionType404}`:
        return {
          ...state,
          showPageNotFound404: (action as FSAP<boolean>).payload || undefined,
        };
    }
  }

  return state || initialState;
}

export {
  setShowPageNotFound404
};

export default commonReducer;
