import type { FSAP } from '../../../actions/base/fsa';
import type { BaseFailedPayload } from '../../../actions/models/payloads/base/BaseFailedPayload';
import type { BaseSucceededPayload } from '../../../actions/models/payloads/base/BaseSucceededPayload';
import type { FetchDataState } from './models/FetchDataState';

function succeedCaseReducer<
  TState extends FetchDataState<TData>,
  TData
>(state: Readonly<TState>, action: Readonly<FSAP<BaseSucceededPayload<TData>>>): TState {
  return {
    ...state,
    isFetching: undefined,
    data: action.payload.data,
    error: undefined,
  };
}

function failedCaseReducer<
  TState extends FetchDataState<unknown>,
>(state: Readonly<TState>, action: Readonly<FSAP<BaseFailedPayload>>): TState {
  return {
    ...state,
    isFetching: undefined,
    data: undefined,
    error: action.payload.error,
  };
}

export {
  failedCaseReducer,
  succeedCaseReducer
};

