function parseUrlArrayTaxonomyFilterValue(value: string | string[] | undefined, taxonomy: string[]): string[] | undefined {
  if (typeof value === 'string') {
    return taxonomy.includes(value) ? [value] : undefined;
  }

  if (Array.isArray(value)) {
    const result = value.filter(v => taxonomy.includes(v));

    return result.length ? result : undefined;
  }

  return undefined;
}

export default parseUrlArrayTaxonomyFilterValue;
