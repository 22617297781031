import type { AnyAction, Reducer } from 'redux';
import type { FSAP } from '../../../../../base/common/store/actions/base/fsa';
import type { MapParamsQuery } from '../../../models/system/map/queries/MapParamsQuery';
import type { MapState } from './models/MapState';

const DEFAULT_MAP_LAT = 52.12102;
const DEFAULT_MAP_LNG = 19.10771;
const DEFAULT_MAP_ZOOM = 5;

const DEFAULT_BOUNDING_BOX_N = 55.03605;
const DEFAULT_BOUNDING_BOX_E = 24.145783;
const DEFAULT_BOUNDING_BOX_S = 49.0020468;
const DEFAULT_BOUNDING_BOX_W = 14.0696389;

const namespace = 'map';

const defaultState: MapState = {
  // Map params should indicate user interaction, otherwise we should use location params or selected country params.
  // params: {
  //   center: {
  //     lat: DEFAULT_MAP_LAT,
  //     lng: DEFAULT_MAP_LNG,
  //   },
  //   zoom: DEFAULT_MAP_ZOOM,
  //   bounds: {
  //     ne: {
  //       lat: DEFAULT_BOUNDING_BOX_N,
  //       lng: DEFAULT_BOUNDING_BOX_E,
  //     },
  //     sw: {
  //       lat: DEFAULT_BOUNDING_BOX_S,
  //       lng: DEFAULT_BOUNDING_BOX_W,
  //     },
  //   },
  // },
};

const mapSetParams = (params: MapParamsQuery): FSAP<MapParamsQuery> => ({
  type: `${namespace}/set-params`,
  payload: params,
});

const mapSetShowMap = (showMap: boolean): FSAP<boolean> => ({
  type: `${namespace}/show-map`,
  payload: showMap,
});

const mapReducer: Reducer<MapState, AnyAction> = (state: MapState | undefined, action: AnyAction): MapState => {
  if (action.type.startsWith(`${namespace}/`)) {
    switch (action.type) {
      case `${namespace}/set-params`:
        return {
          ...state,
          params: (action as unknown as FSAP<MapParamsQuery>).payload,
        };
      case `${namespace}/show-map`:
        return {
          ...state,
          showMap: (action as unknown as FSAP<boolean>).payload,
        };
    }
  }

  return state ?? defaultState;
};

export {
  DEFAULT_BOUNDING_BOX_E,
  DEFAULT_BOUNDING_BOX_N,
  DEFAULT_BOUNDING_BOX_S,
  DEFAULT_BOUNDING_BOX_W,
  DEFAULT_MAP_LAT,
  DEFAULT_MAP_LNG,
  DEFAULT_MAP_ZOOM,
  defaultState,
  mapSetParams,
  mapSetShowMap
};

export default mapReducer;
