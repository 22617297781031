import loadable from '@loadable/component';
import React, { useCallback, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { BASE_RENDERER_ROUTE_PATHS_HOME } from '../../../../framework/base/common/constants/path/baseRendererRoutePaths';
import { BASE_WEB_ROUTE_PATH_SIGNIN, BASE_WEB_ROUTE_PATH_SIGNUP } from '../../../../framework/base/common/constants/path/baseWebRoutePaths';
import useIsAuthenticated from '../../../../framework/base/common/hooks/useIsAuthenticated';
import useMapUrlSearchFromStore from '../../../../framework/gis/common/hooks/useMapUrlSearchFromStore';
import { WEB_ROUTE_PATH_PAGE_CREATE_ADVERT, WEB_ROUTE_PATH_PAGE_MICROCHIP } from '../../../constants/path/webRoutePaths';
import styles from './MainNavigation.module.scss';

const MainNavigationAuthenticated = loadable(() => import(
  /* webpackChunkName: "header-main-navigation-authenticated" */
  './MainNavigationAuthenticated'
));

// let renderCounter = 0;

const MainNavigation: React.FC = () => {

  // console.debug(`Render MainNavigation ${++renderCounter}`);

  const [collapse, setCollapse] = useState(true);

  const search = useMapUrlSearchFromStore();

  const isAuthenticated = useIsAuthenticated();

  const handleOnClickToggleNavigation = useCallback(() => {
    setCollapse((collapse) => !collapse);
  }, []);

  const handleOnClickBlur = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    event.currentTarget.blur();
    setCollapse(true);
  }, []);

  return (
    <>
      <button
        id={styles['app-main-nav-toggler']}
        type='button'
        title='Menu główne'
        onClick={handleOnClickToggleNavigation}
      />
      <nav
        id={styles['app-main-nav-ul']}
        className={!collapse ? styles['show'] : undefined}
      >
        <Link
          id={styles['app-main-nav-create-link']}
          to={WEB_ROUTE_PATH_PAGE_CREATE_ADVERT}
          onClick={handleOnClickBlur}
        >
          Dodaj ogłoszenie
        </Link>
        <NavLink
          to={{
            pathname: BASE_RENDERER_ROUTE_PATHS_HOME,
            search: search ?? '',
          }}
          className={({ isActive }) => `${styles['link-main']}${isActive ? ` ${styles['active']}` : ''}`}
          onClick={handleOnClickBlur}
        >
          Lista ogłoszeń
        </NavLink>
        <NavLink
          to={WEB_ROUTE_PATH_PAGE_MICROCHIP}
          className={({ isActive }) => `${styles['link-main']}${isActive ? ` ${styles['active']}` : ''}`}
          title='Baza danych oznakowanych zwierząt'
          onClick={handleOnClickBlur}
        >
          Baza zwierząt
        </NavLink>
        {!isAuthenticated && (
          <>
            <span className={styles['app-main-nav-signin-or-profile']}>
              <NavLink
                to={BASE_WEB_ROUTE_PATH_SIGNIN}
                className={({ isActive }) => `${styles['link']} ${styles['link']}${isActive ? ` ${styles['active']}` : ''}`}
                onClick={handleOnClickBlur}
              >
                Logowanie
              </NavLink>
            </span>
            <span className={styles['app-main-nav-signup-or-signout']}>
              <NavLink
                to={BASE_WEB_ROUTE_PATH_SIGNUP}
                className={({ isActive }) => `${styles['link']}${isActive ? ` ${styles['active']}` : ''}`}
                onClick={handleOnClickBlur}
              >
                Rejestracja
              </NavLink>
            </span>
          </>
        )}
        {isAuthenticated && (
          <MainNavigationAuthenticated
            classNameLink={styles['link']}
            classNameLinkActive={styles['active']}
            classNameSignInOrProfile={styles['app-main-nav-signin-or-profile']}
            classNameSignUpOrSignOut={styles['app-main-nav-signup-or-signout']}
            onLinkClick={handleOnClickBlur}
          />
        )}
      </nav>
    </>
  );
};

export default MainNavigation;
