import type { AxiosRequestConfig } from 'axios';
import type { IEntitiesQueryMapper } from '../../../common/helpers/mappers/interfaces/IEntitiesQueryMapper';
import type { BaseEntityDetailApiModel } from '../../../common/models/api/base/BaseEntityDetailApiModel';
import type { BaseEntityListItemApiModel } from '../../../common/models/api/base/BaseEntityListItemApiModel';
import type { BaseEntitiesQuery } from '../../../common/models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../common/models/queries/base/BaseEntitiesQueryFilters';
import type { ItemsListModel } from '../../../common/models/system/pagination/ItemsListModel';
import BaseEntitiesServiceReadonly from './BaseEntitiesServiceReadonly';

abstract class BaseEntitiesSearchServiceReadonly<
  TDetailApiModel extends BaseEntityDetailApiModel,
  TQueryFilters extends BaseEntitiesQueryFilters,
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TMapper extends IEntitiesQueryMapper<TQuery>,
  TListItemModel extends BaseEntityListItemApiModel,
> extends BaseEntitiesServiceReadonly<TDetailApiModel>  {

  constructor(routePath: string, protected mapper: TMapper, jwt?: string,) {
    super(routePath, jwt);
  }

  public async search(query: TQuery | undefined, config?: AxiosRequestConfig): Promise<ItemsListModel<TListItemModel>> {
    return this.sendGet<ItemsListModel<TListItemModel>>(query ? this.stringify(query) : '', config);
  }

  public async getFindByQuery(query: TQuery): Promise<TDetailApiModel> {
    return this.sendGetQuery<TDetailApiModel>(`/query`, query);
  }

  protected async sendGetQuery<T>(routePath: string, query: TQuery): Promise<T> {
    return this.sendGet(`${routePath}${this.stringify(query)}`);
  }

  protected stringify = (query: TQuery): string => {
    return this.mapper.stringifyForAPI(query);
  };
}

export default BaseEntitiesSearchServiceReadonly;
