import React, { type PropsWithChildren } from 'react';
import Spinner from '../Spinner';
import styles from './ButtonAccentPrimary.module.scss';
import type { ButtonAccentProps } from './base/ButtonAccentProps';

const ButtonAccentPrimary: React.FC<PropsWithChildren<ButtonAccentProps>> = ({ disabled, isLoading, onClick, children }: PropsWithChildren<ButtonAccentProps>) => {
  return (
    <button
      type='button'
      disabled={disabled || isLoading}
      className={styles['app-button']}
      onClick={onClick}
    >
      {isLoading && (
        <Spinner className='spinner-border-sm me-2' />
      )}
      {children}
    </button>
  );
};

export default ButtonAccentPrimary;
