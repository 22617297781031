abstract class BaseAppCommonConstants {

  protected static instance: BaseAppCommonConstants;

  private paginationDefaultSize: number;

  public get PAGINATION_DEFAULT_SIZE(): number {
    return this.paginationDefaultSize;
  }

  protected constructor(defaultPagination: number) {
    this.paginationDefaultSize = defaultPagination;

    BaseAppCommonConstants.instance = this;
  }

  public static getInstance(): BaseAppCommonConstants {
    if (!BaseAppCommonConstants.instance) {
      throw new Error('AppCommonConstants is not initialized');
    }

    return BaseAppCommonConstants.instance;
  }
}

export default BaseAppCommonConstants;
