import BaseGisEntityTypeEnum from '../../../../framework/gis/common/constants/enums/BaseGisEntityTypeEnum';
import { WEB_URL_PATH_PAGE_CREATE_NEIGHBORHOOD } from '../../../../framework/gis/common/constants/path/baseGisWebUrlPathConstants';
import { TAXONOMY_ANIMAL_CAT, TAXONOMY_ANIMAL_DOG, TAXONOMY_ANIMAL_OTHER, TAXONOMY_TYPE_ADOPT, TAXONOMY_TYPE_FOUND, TAXONOMY_TYPE_LOST } from '../../../constants/TaxonomyConstants';
import AdvertPublicCreatedAtPeriodEnum from '../../../constants/enums/AdvertPublicCreatedAtPeriodEnum';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import { DETAIL_ADVERT_SEGMENT, DETAIL_SHELTER_SEGMENT, DETAIL_VET_SEGMENT } from '../../../constants/path/WebUrlPathsClass';
import { WEB_ROUTE_PATH_PAGE_CREATE_ADVERT, WEB_ROUTE_PATH_PAGE_CREATE_PET, WEB_ROUTE_PATH_PAGE_CREATE_PETSITTER, WEB_ROUTE_PATH_PAGE_CREATE_SHELTER, WEB_ROUTE_PATH_PAGE_CREATE_VET } from '../../../constants/path/webRoutePaths';

interface FriendlyUrlRoutePredefinedQuery {
  animal?: string;
  type?: string;
  locationSlugs?: string[] | undefined;
  tab?: EntityTypeEnum | undefined;
  advertPublicCreatedAtPeriod?: AdvertPublicCreatedAtPeriodEnum | undefined;
}

interface FriendlyUrlRouteConfig {
  predefinedQuery?: FriendlyUrlRoutePredefinedQuery;
  children?: Record<string, FriendlyUrlRouteConfig>;
  useLocationSlug?: true;
  useAdvertSlug?: true;
  useShelterSlug?: true;
  usePetsitterSlug?: true;
  enityType?: EntityTypeEnum;
  formEntityType?: EntityTypeEnum | BaseGisEntityTypeEnum;
  cache?: boolean;
}

const HOME_SEO_ADVERTS_TYPE_ADOPT = '/do-adopcji';
const HOME_SEO_ADVERTS_TYPE_FOUND = '/znalezione';
const HOME_SEO_ADVERTS_TYPE_LOST = '/zaginione';
const HOME_SEO_ADVERTS_ANIMAL_DOG = '/psy';
const HOME_SEO_ADVERTS_ANIMAL_CAT = '/koty';
const HOME_SEO_ADVERTS_ANIMAL_OTHER = '/inne-zwierzeta';
const HOME_SEO_SHELTERS = '/schroniska-dla-zwierzat';
const HOME_SEO_PETSITTERS = '/opieka-nad-zwierzetami';
const HOME_SEO_CREATED_AT_RECENTLY = '/ostatnio';

const recentlyChildrenNoLocation: Record<string, FriendlyUrlRouteConfig> = {
  [HOME_SEO_CREATED_AT_RECENTLY]: {
    predefinedQuery: { advertPublicCreatedAtPeriod: AdvertPublicCreatedAtPeriodEnum.RecentWeek },
  },
};

const recentlyChildrenWithLocation: Record<string, FriendlyUrlRouteConfig> = {
  [HOME_SEO_CREATED_AT_RECENTLY]: {
    predefinedQuery: { advertPublicCreatedAtPeriod: AdvertPublicCreatedAtPeriodEnum.RecentWeek },
    useLocationSlug: true,
  },
};

const animalByTypeChildren: Record<string, FriendlyUrlRouteConfig> = {
  [HOME_SEO_ADVERTS_TYPE_ADOPT]: {
    predefinedQuery: { type: TAXONOMY_TYPE_ADOPT },
    useLocationSlug: true,
    useAdvertSlug: true,
    cache: true,
  },
  [HOME_SEO_ADVERTS_TYPE_FOUND]: {
    predefinedQuery: { type: TAXONOMY_TYPE_FOUND },
    useLocationSlug: true,
    useAdvertSlug: true,
    children: recentlyChildrenWithLocation,
    cache: true,
  },
  [HOME_SEO_ADVERTS_TYPE_LOST]: {
    predefinedQuery: { type: TAXONOMY_TYPE_LOST },
    useLocationSlug: true,
    useAdvertSlug: true,
    children: recentlyChildrenWithLocation,
    cache: true,
  },
};

const HOME_PAGE_FRIENDLY_URLS_REPOSITORY_BY_ANIMAL_PATH: Record<string, FriendlyUrlRouteConfig> = {
  [HOME_SEO_ADVERTS_ANIMAL_CAT]: {
    predefinedQuery: { animal: TAXONOMY_ANIMAL_CAT },
    children: animalByTypeChildren,
    cache: true,
  },
  [HOME_SEO_ADVERTS_ANIMAL_DOG]: {
    predefinedQuery: { animal: TAXONOMY_ANIMAL_DOG },
    children: animalByTypeChildren,
    cache: true,
  },
  [HOME_SEO_ADVERTS_ANIMAL_OTHER]: {
    predefinedQuery: { animal: TAXONOMY_ANIMAL_OTHER },
    children: animalByTypeChildren,
    cache: true,
  },
};

const HOME_PAGE_FRIENDLY_URLS_REPOSITORY_BY_TYPE_PATH: Record<string, FriendlyUrlRouteConfig> = {
  [HOME_SEO_ADVERTS_TYPE_ADOPT]: {
    predefinedQuery: { type: TAXONOMY_TYPE_ADOPT },
    cache: true,
  },
  [HOME_SEO_ADVERTS_TYPE_FOUND]: {
    predefinedQuery: { type: TAXONOMY_TYPE_FOUND },
    children: recentlyChildrenNoLocation,
    cache: true,
  },
  [HOME_SEO_ADVERTS_TYPE_LOST]: {
    predefinedQuery: { type: TAXONOMY_TYPE_LOST },
    children: recentlyChildrenNoLocation,
    cache: true,
  },
};

const HOME_PAGE_FRIENDLY_URLS_REPOSITORY_TABS: Record<string, FriendlyUrlRouteConfig> = {
  [HOME_SEO_SHELTERS]: {
    predefinedQuery: { tab: EntityTypeEnum.Shelter },
    useShelterSlug: true,
    cache: true,
  },
  [HOME_SEO_PETSITTERS]: {
    predefinedQuery: { tab: EntityTypeEnum.Petsitter },
    usePetsitterSlug: true,
    cache: true,
  },
};

const HOME_PAGE_FRIENDLY_URLS_REPOSITORY: Record<string, FriendlyUrlRouteConfig> = {
  // By animal
  ...HOME_PAGE_FRIENDLY_URLS_REPOSITORY_BY_ANIMAL_PATH,
  // By type
  ...HOME_PAGE_FRIENDLY_URLS_REPOSITORY_BY_TYPE_PATH,
  // By tab
  ...HOME_PAGE_FRIENDLY_URLS_REPOSITORY_TABS,
  // Detail
  [DETAIL_ADVERT_SEGMENT]: {
    predefinedQuery: {},
    enityType: EntityTypeEnum.Advert,
  },
  [DETAIL_SHELTER_SEGMENT]: {
    predefinedQuery: {},
    enityType: EntityTypeEnum.Shelter,
  },
  [DETAIL_VET_SEGMENT]: {
    predefinedQuery: {},
    enityType: EntityTypeEnum.Vet,
  },
  // Create
  [WEB_ROUTE_PATH_PAGE_CREATE_ADVERT]: {
    formEntityType: EntityTypeEnum.Advert,
  },
  [WEB_ROUTE_PATH_PAGE_CREATE_PET]: {
    formEntityType: EntityTypeEnum.Pet,
  },
  [WEB_ROUTE_PATH_PAGE_CREATE_PETSITTER]: {
    formEntityType: EntityTypeEnum.Petsitter,
  },
  [WEB_ROUTE_PATH_PAGE_CREATE_SHELTER]: {
    formEntityType: EntityTypeEnum.Shelter,
  },
  [WEB_ROUTE_PATH_PAGE_CREATE_VET]: {
    formEntityType: EntityTypeEnum.Vet,
  },
  [WEB_URL_PATH_PAGE_CREATE_NEIGHBORHOOD]: {
    formEntityType: BaseGisEntityTypeEnum.Neighborhood,
  },
};

class AppRouterFriendlyUrlRepository {

  public static readonly pathByAnimalTaxonomy: Record<string, string> = {
    [TAXONOMY_ANIMAL_CAT]: HOME_SEO_ADVERTS_ANIMAL_CAT,
    [TAXONOMY_ANIMAL_DOG]: HOME_SEO_ADVERTS_ANIMAL_DOG,
    [TAXONOMY_ANIMAL_OTHER]: HOME_SEO_ADVERTS_ANIMAL_OTHER,
  };

  public static readonly pathByTypeTaxonomy: Record<string, string> = {
    [TAXONOMY_TYPE_ADOPT]: HOME_SEO_ADVERTS_TYPE_ADOPT,
    [TAXONOMY_TYPE_FOUND]: HOME_SEO_ADVERTS_TYPE_FOUND,
    [TAXONOMY_TYPE_LOST]: HOME_SEO_ADVERTS_TYPE_LOST,
  };

  public static readonly pathByTab: Record<string, string> = {
    [EntityTypeEnum.Shelter]: HOME_SEO_SHELTERS,
    [EntityTypeEnum.Petsitter]: HOME_SEO_PETSITTERS,
  };
}

export {
  HOME_PAGE_FRIENDLY_URLS_REPOSITORY,
  HOME_PAGE_FRIENDLY_URLS_REPOSITORY_BY_ANIMAL_PATH,
  HOME_PAGE_FRIENDLY_URLS_REPOSITORY_BY_TYPE_PATH,
  HOME_PAGE_FRIENDLY_URLS_REPOSITORY_TABS,
  HOME_SEO_CREATED_AT_RECENTLY,
  HOME_SEO_PETSITTERS,
  HOME_SEO_SHELTERS,
  type FriendlyUrlRouteConfig,
  type FriendlyUrlRoutePredefinedQuery
};

export default AppRouterFriendlyUrlRepository;
