import { useSelector } from 'react-redux';
import type { AuthenticatedUserApiModel } from '../models/api/user/AuthenticatedUserApiModel';
import type { IUserAwareState } from '../store/modules/app/models/IUserAwareState';

const useUser = (): AuthenticatedUserApiModel | undefined => {
  const user = useSelector((state: IUserAwareState) => state.user.user);

  return user;
};

export default useUser;
